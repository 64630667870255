import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-my-commission',
  templateUrl: './my-commission.component.html',
  styleUrls: ['./my-commission.component.css']
})
export class MyCommissionComponent implements OnInit {

  constructor(private globalService: GlobalService) {
    this.globalService.lendingpage = true;
   }

  ngOnInit() {
  }

}
