import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-lendingpage',
  templateUrl: './lendingpage.component.html',
  styleUrls: ['./lendingpage.component.css']
})
export class LendingpageComponent implements OnInit {
  
  constructor(private globalService:GlobalService) { }

  ngOnInit() {
    debugger;
    sessionStorage.removeItem('userName');
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('mobile'); 
      sessionStorage.removeItem('UserId');
      sessionStorage.removeItem('access_token');
      this.globalService.isLogin=false; 
    this.globalService.lendingpage=false;
   
  }

}
