import { Component, OnInit,Input } from '@angular/core';
import { HealthservicesService } from '../services/healthservices.service';

@Component({
  selector: 'app-buildplan-sidebar',
  templateUrl: './buildplan-sidebar.component.html',
  styleUrls: ['./buildplan-sidebar.component.css']
})
export class BuildplanSidebarComponent implements OnInit {
  @Input() show:boolean;
  showCover:boolean=true;
  BaseCoverageList=[];
    KeyList=[];
    HighlightList=[];
    startPage : any;
    paginationLimit:any;
    constructor(private healthMasterService:HealthservicesService) { }
  
    ngOnInit() {
      this.startPage = 0;
      this.paginationLimit = 3;
      debugger
      if(this.show!=undefined)
      this.showCover=this.show;
      let searchtext="Buildplan"; 
      this.healthMasterService.GetBaseCoverage(searchtext).subscribe((res: any[]) => { 
        if (res != null) {
          for (let i = 0; i < res.length; i++) {
            if(res[i].Status =='true'){
              // if(res[i].CategoryName =="Base Coverage"){
              //  this.BaseCoverageList.push({ item_text:res[i].Remarks }); 
              // }
              if(res[i].CategoryName =="Advisory"){
               this.KeyList.push({ item_text:res[i].Remarks }); 
              }
              // if(res[i].CategoryName =="Key Highlights"){
              //  this.HighlightList.push({ item_text:res[i].Remarks }); 
              // }
                    
            }          
          } 
        }
      });
    }
    
    showMoreItems()
   {
      this.paginationLimit = Number(this.paginationLimit) + 3;        
   }
   showLessItems()
   {
     this.paginationLimit = Number(this.paginationLimit) - 3;
   }
  
  }