import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import { Subject } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/health/services/authentication.service';
import { EncrDecrService } from 'src/app/health/services/EncrDecrService';
import { token } from 'src/app/common/Models/token';
import { User, UserAuthentication } from 'src/app/common/Models/userDetail';
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import { QRCodeComponent, QRCodeElementType } from 'angularx-qrcode';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.css']
})
export class CustomerInfoComponent implements OnInit {

  token: token;
  isLogedUser: boolean = false;
  ErrorHandle: ErrorHandle;

  UserId: number;
  FirstName: any = '';
  LastName: any = '';
  MobileNumber: string = '';
  MobileOTP: string;
  EmailId: any = '';
  EmailOTP: string;
  UserName: any = '';
  POSAgentCode: string;

  submitted = false;
  isMobileOTPVerified: boolean = false;
  isEmailOTPVerified: boolean = false;

  public myAngularxQrCode: string = null;
  // public elementType: "canvas" as QRCodeElementType;

  QRCodevalue:string;
  elementType = QRCodeElementType.url;
  margin = 80;



  constructor(private healthMasterService: HealthservicesService, private routerActive: ActivatedRoute, private router: Router,
    private authService: AuthenticationService, private EncrDecr: EncrDecrService, private globalService: GlobalService) {
    debugger;
    if (sessionStorage.getItem('UserId') != null) {
      this.UserId = parseInt(sessionStorage.getItem('UserId'));
      this.UserName = sessionStorage.getItem('userName');
      this.POSAgentCode = sessionStorage.getItem('POSAgentCode');
      //this.Email=sessionStorage.getItem('email');

      this.QRCodevalue=this.routerActive.snapshot.queryParamMap.get('userid');
    } else {
      //this.router.navigate(['/login']);
    }
  }


  ngOnInit() {
    this.globalService.lendingpage = true;
    debugger
    if (sessionStorage.getItem('UserId') == null) {
      let getValues = this.routerActive.snapshot.queryParamMap.get('userid');

      this.QRCodevalue=getValues.toString();

      var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', getValues);
      var data = JSON.parse(decrypted).split('|');
      console.log(data);
      // alert(data);
      debugger
      let vUserAuthentication = new UserAuthentication();
      vUserAuthentication.UserName = data[0];
      vUserAuthentication.Password = data[1];
      this.authService.getAuthentication(vUserAuthentication).subscribe((data: token) => {
        this.token = data;
        if (this.token.access_token !== '') {
          sessionStorage.setItem('access_token', this.token.access_token);
          //get user 
          this.authService.geUser(vUserAuthentication.UserName)
            .subscribe((data: User[]) => {
              if (data.length > 0) {
                this.isLogedUser = true;
                this.globalService.isLogin = true;
                this.globalService.userName = 'Welcome,' + data[0].UserName;

                this.UserName = data[0].UserName;
                this.globalService.isCustomer = true;
                sessionStorage.setItem('isCustomer', 'true');

                if (sessionStorage.getItem('UserId') == null) {

                  sessionStorage.setItem('UserId', data[0].UserId.toString());
                  sessionStorage.setItem('userName', data[0].UserName);
                  sessionStorage.setItem('email', data[0].Email);
                  sessionStorage.setItem('mobile', data[0].Mobile);

                  sessionStorage.setItem('POSAgentCode', data[0].ProfileId);
                  // alert(data[0].ProfileId)


                  window.location.reload();

                }
                if (data[0].UserName == "") {
                  Swal.fire('Userid and password not matched', '', 'error');
                }
              } else {
                Swal.fire('Userid and password not matched', '', 'error');
              }
            }, ((err: ErrorHandle) => {
              this.ErrorHandle = err;
              Swal.fire(this.ErrorHandle.statusText, '', 'error');
            }));
        }
        else {
          Swal.fire('Some error.', '', 'error');
        }

      }, ((err: ErrorHandle) => {
        this.ErrorHandle = err;
        if (this.ErrorHandle.statusText == "Bad Request") {
          Swal.fire('Userid and password are incorrect.', '', 'error');
        }
      }));
      localStorage.setItem('UserId', vUserAuthentication.UserName);

    }





  }

  captureOTP(OTPType: any) {
    debugger;
    this.submitted = true;
    if (OTPType == 'M' && (this.MobileNumber == '' || this.MobileNumber == undefined)) {
      Swal.fire("", "Kindly Enter Mobile Number", "error");
      return;
    }

    if (OTPType == 'E' && (this.EmailId == '' || this.EmailId == undefined)) {
      Swal.fire("", "Kindly Enter Email Id", "error");
      return;
    }
    // if (this.Loginform.invalid) {
    //   return;
    // }

    // if (this.MobileNumber == '9421187649' || this.MobileNumber == '9967740018') {

    //   this.IsAdmin = 'true';
    // }


    var val = {
      IsActive: true,
      UserId: this.MobileNumber,
      MobileNo: this.MobileNumber,
      OTPExpiry: "",
      EmailId: this.EmailId,
      Remarks: OTPType,
      Status: "",
      CreatedBy: "",
      CreatedByIP: ""
    }

    // this.spinner.show();

    this.healthMasterService.SendOTP(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        // this.otpBox = "Y";
        // this.phoneBox = "N";
        if (OTPType == 'M')
          Swal.fire("", "OTP sent to mobile number", "success");
        else
          Swal.fire("", "OTP sent to Email id", "success");
      }
      else {
        Swal.fire("", "OTP sending failed", "error");
        this.MobileNumber = '';
      }

      // setTimeout(() => {
      //   this.spinner.hide();
      // }, 1000);

    });


  }

  OTPValidation(OTPType: any) {

    this.submitted = true;
    // if (this.form1.invalid) {
    //   return;
    // }

    if (OTPType == 'M' && (this.MobileOTP == '' || this.MobileOTP == undefined)) {
      Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
      return;
    }

    if (OTPType == 'E' && (this.EmailOTP == '' || this.EmailOTP == undefined)) {
      Swal.fire("", "Kindly Enter OTP sent on your Email id", "error");
      return;
    }

    // if(this.OTPSuccess==false){
    //   Swal.fire("", "Time out!! Please resend OTP", "error");
    //   return false;
    // }

    var val = {
      IsActive: true,
      UserId: this.MobileNumber,
      MobileNo: this.MobileNumber,
      OTPExpiry: "",
      EmailId: this.EmailId,
      Remarks: "",
      Status: "",
      CreatedBy: "",
      CreatedByIP: ""
    }
    this.healthMasterService.OTPValidation(val).subscribe((data: any) => {
      debugger;
      // if (this.MobileOTP == data[0].OTP && this.EmailOTP == data[0].EOTP) {
      //   localStorage.setItem("UserId", data[0].UserId);
      //   localStorage.setItem("UserEmail", this.EmailId);
      //   // this.OTPSuccess=true;
      //   // this.SelectUserProfileById();

      // }
      // else {
      // this.OTPSuccess=false;

      if (OTPType == 'M' && (this.MobileOTP == data[0].OTP)) {
        //Swal.fire("", "Mobile OTP is correct", "success");
        this.isMobileOTPVerified = true;
      }
      else if (OTPType == 'E' && (this.EmailOTP == data[0].EOTP)) {
        //Swal.fire("", "Email OTP is correct", "success");
        this.isEmailOTPVerified = true;
      }
      else if (OTPType == 'M' && (this.MobileOTP != data[0].OTP)) {
        Swal.fire("", "Mobile OTP is not correct", "error");
      }
      else if (OTPType == 'E' && (this.EmailOTP != data[0].EOTP)) {
        Swal.fire("", "Email OTP is not correct", "error");
      }
      else {
        Swal.fire("", "Please provide correct OTP", "error");
      }

      //}
    });

    ////

  }

  Continue() {
   // this.router.navigate(['/home']);

    if (this.FirstName == '' || this.FirstName == undefined) {
      Swal.fire("", "Please Enter First Name", "error");
      return false;
    }
    else if (this.LastName == '' || this.LastName == undefined) {
      Swal.fire("", "Please Enter Last Name", "error");
      return false;
    }
    else if (this.MobileNumber == '' || this.MobileNumber == undefined){
      Swal.fire("", "Kindly Enter Mobile Number", "error");
      return;
    }

    // else if (this.EmailId == '' || this.EmailId == undefined){
    //   Swal.fire("", "Kindly Enter Email Id", "error");
    //   return;
    // }


    else if (this.isMobileOTPVerified == false) {
      Swal.fire("", "Mobile OTP not verified", "error");
      return false;
    }
    // else if (this.isEmailOTPVerified == false) {
    //   Swal.fire("", "Email OTP not verified", "error");
    //   return false;
    // }
    else {
      sessionStorage.setItem('CustomerName',this.FirstName+' '+this.LastName);
      sessionStorage.setItem('EmailId',this.EmailId);
      sessionStorage.setItem('MobileNumber',this.MobileNumber);
      this.router.navigate(['/home']);
    }


  }

  keyPress($event) {
    const pattern = /[0-9]/;
    const inputChar = $event.key;//String.fromCharCode($event).charCodeAt;
    if (!pattern.test(inputChar.toString())) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  alphaOnly($event) {
    var pattern = /^[a-zA-Z ]*$/;
    //var pattern = /^[A-Za-z]+$/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  // saveAsImage(parent) {
  //   // fetches base 64 date from image
  //   const parentElement = parent.el.nativeElement.querySelector("img").src;

  //   // converts base 64 encoded image to blobData
  //   let blobData = this.convertBase64ToBlob(parentElement);

  //   // saves as image
  //   if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
  //     window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
  //   } else { // chrome
  //     const blob = new Blob([blobData], { type: "image/png" });
  //     const url = window.URL.createObjectURL(blob);
  //     // window.open(url);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = 'Qrcode';
  //     link.click();
  //   }

  // }

  saveAsImage(parent: any) {
    debugger
    let parentElement = null

    if (this.elementType === "canvas") {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")
    } else if (this.elementType === "img" || this.elementType === "url") {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src
    } else {
      Swal.fire("", "Set elementType to 'canvas', 'img' or 'url'.", "error");
    }

    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = "angularx-qrcode"
      link.click()
    }
  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }

}
