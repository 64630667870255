import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-template1',
  templateUrl: './template1.component.html',
  styleUrls: ['./template1.component.css']
})
export class Template1Component implements OnInit {
 public Step1: boolean= true;
 public Step2: boolean=false;
 public Step3: boolean=false;
 public Step4: boolean=false;
 public Step5: boolean=false;
  constructor() { }

  ngOnInit() {
    $('.tooltips').append("<span></span>");
    $('.tooltips:not([tooltip-position])').attr('tooltip-position', 'bottom');
    $(".tooltips").mouseenter(function () {
      $(this).find('span').empty().append($(this).attr('tooltip'));
    });
  }
  RedireToStep1(){
    this.Step1=true;
    this.Step2=false;
    this.Step3=false;
    this.Step4=false;
  }
  RedireToStep2(){
    this.Step1=false;
    this.Step2=true;
    this.Step3=false;
    this.Step4=false;
  }
  RedireToStep3(){
    this.Step1=false;
    this.Step2=false;
    if (localStorage.getItem('CoverType') == 'Individual') {
      this.Step3=true;
    }else{
      this.Step4=true;
    }   
    
  }
  RedireToStep4(){
    // this.Step1=false;
    // this.Step2=false;
    // this.Step3=false;
    // this.Step4=true;
    alert('work in progress..')
  }
  CoverType(type:string){
    //alert(type);
    if (localStorage.getItem('CoverType') == null) {
      localStorage.setItem('CoverType', type);
    }
    else{
      localStorage.setItem('CoverType', type);
    }
  }
}
