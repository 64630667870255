import { BrowserModule } from '@angular/platform-browser';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HealthComponent } from './health/health/health.component';
//import { HomeComponent } from './home/home.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { BuildplanComponent } from './health/buildplan/buildplan.component';
import { QuotationsComponent } from './health/quotations/quotations.component';
import { QuotationComponent } from './health/quotation/quotation.component';
import { ViewplanComponent } from './health/viewplan/viewplan.component';
import { ProposalComponent } from './health/proposal/proposal.component';
import { CompareComponent } from './health/compare/compare.component';
import { LoginComponent } from './common/account/login/login.component';
import { Template1Component } from './health/template1/template1.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule, DatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ChartComponent } from './common/chart/chart.component';
import { SidelistComponent } from './health/sidelist/sidelist.component';
import { SidecoversComponent } from './health/sidecovers/sidecovers.component';
import { PaymentsuccessComponent } from './health/paymentsuccess/paymentsuccess.component';
import { PaymentfailedComponent } from './health/paymentfailed/paymentfailed.component';
import { ProfileComponent } from './common/profile/profile.component';
import { QuotesComponent } from './common/quotes/quotes.component';
import { DataTablesModule } from 'angular-datatables';
import { FavouriteComponent } from './common/favourite/favourite.component';
import { PrivacyPolicyComponent } from './common/privacy-policy/privacy-policy.component';
import { PaymentsummeryComponent } from './health/paymentsummery/paymentsummery.component';
import { BuildplanSidebarComponent } from './health/buildplan-sidebar/buildplan-sidebar.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaterialModule } from './material.module';
import { MatNativeDateModule } from '@angular/material';
import { MypolicyComponent } from './common/mypolicy/mypolicy.component';
import { LeftpanelComponent } from './common/dashboard/leftpanel/leftpanel.component';
import { QuotationListComponent } from './common/quotation-list/quotation-list.component';
import { EncrDecrService } from './health/services/EncrDecrService';
import { LendingpageComponent } from './lendingpage/lendingpage.component';
import { LendinglayoutComponent } from './lendinglayout/lendinglayout.component';
import { LoginComponent_POSP } from 'src/app/login/login.component';
import { RegisterPospComponent } from './health/register-posp/register-posp.component';
import { AddDetailsComponent } from './health/add-details/add-details.component';
import { AddPosDetailsComponent } from './health/add-pos-details/add-pos-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardPOSPComponent } from './health/dashboard-posp/dashboard-posp.component';
import { ProcessproposalComponent } from './proposal/processproposal/processproposal.component'; 
import { POSPheaderComponent } from './health/header/pospheader/pospheader.component';
import { POSPfooterComponent } from './health/footer/pospfooter/pospfooter.component';
import { AcceptedPosComponent } from './health/accepted-pos/accepted-pos.component';
import { RejectedPosComponent } from './health/rejected-pos/rejected-pos.component';
import { TotalPosComponent } from './health/total-pos/total-pos.component';
import { DashboardComponent_POSP } from './dashboard/dashboard.component';
import { OnholdPospComponent } from './health/onhold-posp/onhold-posp.component';
import { ApilogComponent } from './apilog/apilog.component';

import { MyinspectionComponent } from './common/myinspection/myinspection.component';
import { MyCommissionComponent } from './common/MyCommission/my-commission/my-commission.component';
import { MyBusinessComponent } from './common/MyBusiness/my-business/my-business.component';
import { RenewalComponent } from './common/renewal/renewal.component';
import { MyrenewalComponent } from './common/myrenewal/myrenewal.component';
import { ChangePasswordComponent } from './common/account/change-password/change-password.component';
import { SafePipe } from './safe.pipe';
import { PospPendingRequestComponent } from './health/posp-pending-request/posp-pending-request.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CustomerInfoComponent } from './common/customer-info/customer-info.component';
import { QRCodeModule } from 'angularx-qrcode';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { HealthCompareComponent } from './health/health-compare/health-compare.component';

@NgModule({
  declarations: [
    AppComponent,
    HealthComponent,
    //HomeComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BuildplanComponent,
    QuotationsComponent,
    ViewplanComponent,
    ProposalComponent,
    CompareComponent,
    LoginComponent,
    ApilogComponent,
    Template1Component,
    QuotationComponent,
    ChartComponent,
    SidelistComponent,
    SidecoversComponent,
    PaymentsuccessComponent,
    PaymentfailedComponent,
    ProfileComponent,
    QuotesComponent,
    FavouriteComponent,
    PrivacyPolicyComponent,
    PaymentsummeryComponent,
    BuildplanSidebarComponent,
    DashboardComponent,
    MypolicyComponent,
    LeftpanelComponent,
    QuotationListComponent,
    LendingpageComponent,
    LendinglayoutComponent,
    LoginComponent_POSP,
    RegisterPospComponent,
    AddDetailsComponent,
    AddPosDetailsComponent,
    DashboardPOSPComponent,
    ProcessproposalComponent,
    POSPheaderComponent,
    POSPfooterComponent,
    AcceptedPosComponent,
    RejectedPosComponent,
    TotalPosComponent,
    DashboardComponent_POSP,
    OnholdPospComponent,
    MyCommissionComponent,
    MyBusinessComponent,
    MyinspectionComponent,
    RenewalComponent,
    MyrenewalComponent,
    ChangePasswordComponent,
    SafePipe,
    PospPendingRequestComponent,
    CustomerInfoComponent,
    HealthCompareComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    BrowserAnimationsModule,
    DataTablesModule, MaterialModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgbModule,
    PdfViewerModule,
    QRCodeModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [BsDatepickerConfig, MatDatepickerModule, EncrDecrService],//, BnNgIdleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
