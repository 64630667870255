import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import Validation from 'src/app/health/services/validation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent_POSP implements OnInit {

  constructor(private formBuilder: FormBuilder) { }
  form: FormGroup = new FormGroup({});
  submitted = false;
  ngOnInit(): void {



  }

}
