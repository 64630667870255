'use strict';
//import {  Headers} from '@angular/common/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
export const sep='/';
export const version: string="22.2.2";  
export const apiUrl='http://adeptinfo.co.in:3035/api/';
// export const apiUrl='http://localhost:11274/api/';

export class AppSettings {
  
  //  public static DomainUrl='https://uat.bimaraja.com:3452';//uat;
  // public static DomainUrlCare='https://careuat.bimaraja.com';//uat 
  // public static DomainUrlMotor='https://uat.bimaraja.com:4644/';//uat
  // public static DomainUrlGCV='https://uat.bimaraja.com:6315/';//uat
  // public static DomainUrlMotorCar='https://uat.bimaraja.com:4845/';//uat

  // public static DomainUrlMotorCarInspection='https://uat.bimaraja.com:4521/';//uat
  public static DomainUrlMotorCarInspection='https://bimaraja.com:7010/';//prod

  public static DomainUrl='https://www.riskcareinsure.com:8089';//prod;
  public static DomainUrlCare='https://www.riskcareinsure.com:8089';//prod 
  public static DomainUrlMotor='https://bimaraja.com:7149/';//prod
  // public static DomainUrlGCV='http://adeptinfo.co.in:6315/';//prod
  public static DomainUrlGCV='https://bimaraja.com:6257/';//prod
  public static DomainUrlMotorCar='https://bimaraja.com:7267/';//prod

  // public static DomainUrl='http://localhost:11274';//devlopment

    // public static API_ENDPOINT='https://uat.bimaraja.com:3452/api';//uat
public static API_ENDPOINT='https://www.riskcareinsure.com:8089/api';//prod
  //  public static API_ENDPOINT='http://localhost:11274/api'; //devlopment

    //public static API_ENDPOINT='http://adeptinfo.co.in:3407/api/v1.0';
   
    // public static API_ENDPOINT_CKYC='https://uat.bimaraja.com:6631/api'; // CKYC UAT
    public static API_ENDPOINT_CKYC='https://bimaraja.com:8383/api'; // CKYC prod

}

export class CommonClass{
public static getHttpHeader():HttpHeaders{

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return headers;
}

}