import { Router } from '@angular/router';
import { Component, ViewChild, OnInit, HostListener, Inject, Injectable } from '@angular/core';
import { GlobalService } from './global.service'
import { HeaderComponent } from './common/header/header.component';
import { AuthenticationService } from 'src/app/health/services/authentication.service';
import { User, UserAuthentication } from './common/Models/userDetail';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { token } from './common/Models/token';
import { ErrorHandle } from './common/models/ErrorHandle';
import Swal from 'sweetalert2';
import { HttpHeaders } from '@angular/common/http';
import { QRCodeElementType } from 'angularx-qrcode';
import { EncrDecrService } from './health/services/EncrDecrService';
import { DOCUMENT } from '@angular/common';
// import { BnNgIdleService } from 'bn-ng-idle';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { HealthservicesService } from 'src/app/health/services/healthservices.service';

declare var $: any;

const headers = new HttpHeaders()
  .set('Strict-Transport-Security', 'max-age=31536000');

@Component({
  selector: 'app-root',

  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isShow: boolean;
  topPosToStartShowing = 100;

  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    console.log('[scroll]', scrollPosition);

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  title = '';
  isLogin: boolean;
  isCustomer: boolean;

  @ViewChild(HeaderComponent, { static: false }) headerComponent: HeaderComponent;
  user: any;
  registerForm: FormGroup;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  isLogedUser: boolean = false;

  isRegister: boolean;
  ErrorHandle: ErrorHandle;
  token: token;
  loginUser: User[];
  UserAuthentication: UserAuthentication;
  logedUser: string = null;
  PosMobileNo: string = "";
  public myAngularxQrCode: string = null;
  // public elementType: "canvas" as QRCodeElementType;

  QRCodevalue: string;
  elementType = QRCodeElementType.url;
  margin = 80;
  domain = '';

  idleState = '';
  timedOut = false;
  lastPing?: Date = null;


  constructor(private healthMasterService: HealthservicesService, public globalService: GlobalService
    , private authService: AuthenticationService, private idle: Idle, private keepalive: Keepalive,//private bnIdle: BnNgIdleService,
    private formBuilder: FormBuilder, private router: Router, private EncrDecr: EncrDecrService, @Inject(DOCUMENT) private document: any) {
    debugger;
    this.globalService.lendingpage = false;
    if (localStorage.getItem('userName') != null) {
      sessionStorage.setItem('userName', localStorage.getItem('userName'));
    }
    if (sessionStorage.getItem('userName') == null) {
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('mobile');
      this.globalService.isLogin = false;
      this.isLogin = false;
    } else {
      this.globalService.isLogin = true;
      this.isLogin = true;
      this.logedUser = 'Welcome,' + sessionStorage.getItem('userName');

      if (sessionStorage.getItem('isCustomer') == 'true') {
        this.isCustomer = true;
      } else {
        this.isCustomer = false;
      }
      if (this.isCustomer == true) {
        if (sessionStorage.getItem('mobile') != null) {
          this.PosMobileNo = sessionStorage.getItem('mobile');
        }
        if (this.isLogin == true) {
          idle.setIdle(5);
          idle.setTimeout(900);
          idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
          idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
            this.reset();
          });
          idle.onTimeout.subscribe(() => {
            debugger;
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.sendMail();
            this.userLogout();
          });
          idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!'
          });
          idle.onTimeoutWarning.subscribe((countdown) => {debugger;
            let minutes = Math.floor(countdown / 60);
            let extraSeconds = countdown % 60;
            minutes = minutes < 10 ? 0 + minutes : minutes;
            extraSeconds = extraSeconds < 10 ? 0 + extraSeconds : extraSeconds;
            this.idleState = 'Your session will expire in ' + minutes + ':' + extraSeconds+ ' seconds!'
            if(countdown<1){

            }
          });
          this.reset();
        }
      }
    }
    // this.isLogin = true;
    this.isRegister = false;

    // this.bnIdle.startWatching(10).subscribe((res) => {
    //   if(res) {
    //       console.log("session expired");
    //       Swal.fire('session expired', '', 'error');
    //   }
    // })
    

  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  ngOnInit() {
    if (sessionStorage.getItem('email') != null) {
      this.authService.geUser(sessionStorage.getItem('email')).subscribe((res: User) => {
        this.user = res[0].UserName;

        let Checkit = res[0].Email + '|' + res[0].Password;
        var encrypted = this.EncrDecr.set('123456$#@$^@1ERF', JSON.stringify(Checkit));
        this.domain = this.document.location.origin;
        let RedictionUrl = this.domain + '/Customer-Info?userid=' + encrypted;
        this.QRCodevalue = RedictionUrl;
      });
    }
    this.registerForm = this.formBuilder.group({
      RoleId: new FormControl(4),
      UserName: new FormControl(null, Validators.required),
      Email: new FormControl(null, [Validators.required, Validators.email]),
      Mobile: new FormControl(null, [Validators.required, Validators.pattern("[0-9]{10}")]),
      Password: new FormControl(null, [Validators.required, Validators.minLength(6)])
    });
    this.setUserCategoryValidators();
  }
  
  sendMail() {

    if (sessionStorage.getItem('email') != null) {
      let from = "support@riskcare.co.in";

      let agentname = sessionStorage.getItem('userName');

      let emailid = sessionStorage.getItem('email');

      let name = sessionStorage.getItem('CustomerName');
      
      let mobile = sessionStorage.getItem('MobileNumber');

      this.healthMasterService.SendPOSPMail(agentname,emailid,name,mobile).subscribe((data: any) => {

      });
    }
  }

  showLogin() {
    if (this.globalService.isLogin == false) {
      $("#showLoginmodal").modal("show");
    }
  }

  Login(loginCheck) {
    if (loginCheck == "register") {
      this.isLogin = false;
      this.isRegister = true;
    } else {
      this.isLogin = true;
      this.isRegister = false;
    }
  }
  userLogin() {
    this.submitted = true;
    this.setUserCategoryValidators();
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    let vUserAuthentication = new UserAuthentication();
    vUserAuthentication.UserName = this.registerForm.value.Email;
    vUserAuthentication.Password = this.registerForm.value.Password;
    this.authService.getAuthentication(vUserAuthentication).subscribe((data: token) => {
      this.token = data;
      if (this.token.access_token !== '') {
        this.loading = false;
        sessionStorage.setItem('access_token', this.token.access_token);
        //this.router.navigate(["/dashboard"]);
        this.GetUserDetails(this.registerForm.value.Email);
      }
      else {
        this.loading = false;
        Swal.fire('Some error.', '', 'error');
      }

    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Userid and password are incorrect.', '', 'error');
      }
    }));
    //loginUser
  }
  get r() { return this.registerForm.controls; }
  userRegister() {
    this.submitted = true;
    this.setUserCategoryValidators();
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.userRegisterForm(this.registerForm.value).subscribe((data) => {
      if (data > 0) {
        this.loading = false;
        Swal.fire('User registration successfully.', '', 'success');
        this.getAuthentication(this.registerForm.value.Email, this.registerForm.value.Password)
        //this.GetUserDetails(this.registerForm.value.Email);
      }
      else {
        this.loading = false;
        Swal.fire('Some error.', '', 'error');
      }

    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      Swal.fire(this.ErrorHandle.statusText, '', 'error');
    }));
  }
  getAuthentication(username, password) {
    let vUserAuthentication = new UserAuthentication();
    vUserAuthentication.UserName = username;
    vUserAuthentication.Password = password;
    this.authService.getAuthentication(vUserAuthentication).subscribe((data: token) => {
      this.token = data;
      if (this.token.access_token !== '') {
        this.loading = false;
        sessionStorage.setItem('access_token', this.token.access_token);
        //this.router.navigate(["/dashboard"]);
        this.GetUserDetails(username);
      }
      else {
        this.loading = false;
        Swal.fire('Some error.', '', 'error');
      }

    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Userid and password are incorrect.', '', 'error');
      }
    }));

  }
  GetUserDetails(username) {
    this.authService.geUser(username)
      .subscribe((data: User[]) => {
        if (data.length > 0) {
          this.globalService.lendingpage = true;
          this.isLogedUser = true;
          this.globalService.isLogin = true;
          this.globalService.userName = 'Welcome,' + data[0].UserName;
          sessionStorage.setItem('UserId', data[0].UserId.toString());
          sessionStorage.setItem('userName', data[0].UserName);
          sessionStorage.setItem('email', data[0].Email);
          sessionStorage.setItem('mobile', data[0].Mobile);
          if (data[0].UserName != "") {
            $("#showLoginmodal").modal("hide");
            // this.savePremium('proposal');
          } else {
            Swal.fire('Userid and password not matched', '', 'error');
          }
        } else {
          Swal.fire('Userid and password not matched', '', 'error');
        }
      }, ((err: ErrorHandle) => {
        this.ErrorHandle = err;
        Swal.fire(this.ErrorHandle.statusText, '', 'error');
      }));

  }
  setUserCategoryValidators() {
    const name = this.registerForm.get('UserName');
    const emailId = this.registerForm.get('Email');
    const password = this.registerForm.get('Password');
    const mobileNo = this.registerForm.get('Mobile');
    if (this.isLogin === true) {
      mobileNo.setValidators(null);
      name.setValidators(null);

      emailId.setValidators([Validators.required, Validators.email]);
      password.setValidators([Validators.required, Validators.minLength(6)]);
    }
    if (this.isRegister === true) {
      mobileNo.setValidators([Validators.required, Validators.pattern("[0-9]{10}")]);
      name.setValidators([Validators.required]);
      emailId.setValidators([Validators.required, Validators.email]);
      password.setValidators([Validators.required, Validators.minLength(6)]);
    }
    name.updateValueAndValidity();
    mobileNo.updateValueAndValidity();
    emailId.updateValueAndValidity();
    password.updateValueAndValidity();

  }

  userLogout() {
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('mobile');
    sessionStorage.removeItem('UserId');
    sessionStorage.removeItem('access_token');
    localStorage.clear();
    sessionStorage.clear();
    this.globalService.isLogin = false;
    this.isLogin = false;
    // window.location.reload();
    // this.router.navigate(['/health']);
    this.router.navigate(['/'])
      .then(() => {
        window.location.reload();
      });
  }

  rememberMe(isChecked: boolean) {
    if (isChecked) {
      let emilid = this.registerForm.value.Email;
      if (emilid != null) {
        localStorage.setItem('email', emilid);
      }
    } else {
      localStorage.removeItem('email');
    }
  }



  saveAsImage(parent: any) {
    debugger
    let parentElement = null

    if (this.elementType === "canvas") {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")
    } else if (this.elementType === "img" || this.elementType === "url") {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.")
    }

    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = "angularx-qrcode"
      link.click()
    }
  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }
}
