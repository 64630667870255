import { Component, OnInit,EventEmitter } from '@angular/core';

//import {MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';

import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import { Subject } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
// import { MomentDateAdapter } from '@angular/material-moment-adapter';
// import{MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter, MAT_DATE_LOCALE} from '@angular/material';

declare var $: any;
// export const MY_FORMATS = {
//   parse: {
//       dateInput: 'LL'
//   },
//   display: {
//       dateInput: 'DD-MM-YYYY',
//       monthYearLabel: 'YYYY',
//       dateA11yLabel: 'LL',
//       monthYearA11yLabel: 'YYYY'
//   }
// };
export const MY_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
//import { DateFormat } from "../../common/date-format";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
@Component({
  selector: 'app-mypolicy',
  templateUrl: './mypolicy.component.html',
  styleUrls: ['./mypolicy.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
    ,
     { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class MypolicyComponent implements OnInit {
  date:any=[];
  serializedDate:any;
  UserId:number;
  Email:any;
  fromDate:any='';
  toDate:any='';
  policyNumber:string='';
  policies=[];
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
 
  constructor(private healthMasterService:HealthservicesService,private router:Router,private globalService: GlobalService) {
    if(sessionStorage.getItem('UserId')!=null){     
      this.UserId=parseInt(sessionStorage.getItem('UserId'));
      this.Email=sessionStorage.getItem('email');
    }else{
      this.router.navigate(['/login']);
    }
   }
   
  ngOnInit() {
   debugger;
   this.globalService.lendingpage = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
    $("#dashboard_left_panel").click(function(){
      $(".left_panel").toggleClass("show_left_panel");
    });

    if(sessionStorage.getItem('toDate') !=null){
      this.policyNumber=sessionStorage.getItem('policyNumber');
      this.fromDate=new Date(sessionStorage.getItem('fromDate'));//sessionStorage.getItem('fromDate')
      this.toDate=new Date(sessionStorage.getItem('toDate'));//sessionStorage.getItem('toDate')
    }
    
    this.healthMasterService.GetPolicies(this.Email,this.policyNumber,this.fromDate,this.toDate).subscribe((res:any)=>{
      this.policies=res;
      this.dtTrigger.next(); 
      });
    
  }

  searchPolicy(){  
    debugger 
    
      sessionStorage.setItem('policyNumber',this.policyNumber.toString()); 
      sessionStorage.setItem('fromDate',this.fromDate);
      sessionStorage.setItem('toDate',this.toDate); 
    
    this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/mypolicy']);
      });
    
  }

  buttonInRowClick(id,QuotionId){
    localStorage.setItem('quotaionId',QuotionId.toString()); 
    this.router.navigate(['/proposal'], { queryParams: { id: id } })
  }

}
