import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from '../services/healthservices.service';

@Component({
  selector: 'app-sidecovers',
  templateUrl: './sidecovers.component.html',
  styleUrls: ['./sidecovers.component.css']
})
export class SidecoversComponent implements OnInit {
  BaseCoverageList=[];
  KeyList=[];
  HighlightList=[];
  startPage : any;
  paginationLimit:any;
  
  constructor(private healthMasterService:HealthservicesService) { }

  ngOnInit() {
    this.startPage = 0;
    this.paginationLimit = 3;

    let searchtext="Quotations"; 
    this.healthMasterService.GetBaseCoverage(searchtext).subscribe((res: any[]) => { 
      if (res != null) {
        for (let i = 0; i < res.length; i++) {
          if(res[i].Status =='true'){
            // if(res[i].CategoryName =="Sample Base Coverage"){
            //  this.BaseCoverageList.push({ item_text:res[i].Remarks }); 
            // }
            if(res[i].CategoryName =="Advisory"){
             this.KeyList.push({ item_text:res[i].Remarks }); 
            }
            // if (res[i].CategoryName == "Key Highlights") {
            //   this.HighlightList.push({ item_text: res[i].Remarks });
            // }
                  
          }          
        } 
      }
    });
  }

  showMoreItems()
   {
      this.paginationLimit = Number(this.paginationLimit) + 3;        
   }
   showLessItems()
   {
     this.paginationLimit = Number(this.paginationLimit) - 3;
   }

}
