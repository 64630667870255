import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-processproposal',
  templateUrl: './processproposal.component.html',
  styleUrls: ['./processproposal.component.css']
})
export class ProcessproposalComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
  ) { }

  ProposalId:string;
  ProductId:string;

  ngOnInit() {
    debugger;
    this.route.queryParamMap.subscribe(queryParams => {
      this.ProposalId = queryParams.get("ProposalId");
      this.ProductId = queryParams.get("ProductId");
   })
    // window.open('https://uat.bimaraja.com:3452/proposal/Processproposal?ProposalId='+this.ProposalId+'&ProductId='+this.ProductId,"_self");
    window.location.href='https://uat.bimaraja.com:3452/proposal/Processproposal?ProposalId='+this.ProposalId+'&ProductId='+this.ProductId;
  }

}
