import {insuredMember} from './premium.models';

export class address{
    email:string;
    mobile:number;
    emergencyMobile:string;
    pincode:number;
    state:string;
    city:string;
    stateCode:string;
    cityId:number;
    area:string;
    address1:string;
    address2:string;
    landmark:string='';
    maritalStatusCode:string;
    PanIndiaCover:string;
    RentCappingDiscount:string;
    WaiverRent:string;
     //SAtish
    //WaiverRent:number;
    //SAtish
    NonMedical:number;
    HPR:string;
    HPRValue:string="";
    ProposerDOB:string;
    EmailOTP:string="";
}

export class Salutation{
    SalutationId: number;
    SalutationCode: string;
    SalutationName: string;
  }

  export class Occupation{    
        HealthOccupationId: number;
        HealthOccupationCode: string;
        HealthOccupationName: string;      
  }

  export class NomineeRelation    {
      NomineeRelationshipId:number;
      RelationShipCode: string;
      NomineeRelationshipName: string;
    }
  

  export class Questionaire{
    SeqNo:number;
    HealthQuestionId:number;
    HealthQuestionName:string;
    HealthQuestionShortForm:string;
    LobId:number;
    CompanyId:number;
    QuestionValue:string;
    QuestionData:string;
    MemberData:any[];
  }

  export class RCBSData {
    customerName : string;
    address  : string;
    dob  : string;
    landline  : string;
    mobile  : string;
    alternateMobile  : string;
    emailID  : string;
    panCard  : string;
    aadhar  : string;
    parentOrChild  : string;
    rmName  : string;
    branch  : string;
    lobName  : string;
    tdsPercent  : string;
    fromDate  : string;
    toDate  : string;
    subPartnerName  : string;
    educationQualification  : string;
    accountName  : string;
    accountNumber  : string;
    bankName  : string;
    ifscCode  : string;
    bankBranchAddress  : string;
    posUserType  : string;
    assignProducts  : string;
    productPlan  : string;
    category  : string;
    isActive  : string;
    createdBy  : string;
    images : images[];
  }

  export class images {
    uniqueID : string;
    attachmentID : string;
    businessCode : string;
    fileName : string;
    fileType : string;
    filePath : string;
    fileSize : string;
    fileContent : string;
    fileTypeID : string;
  }
  export class InitiateCKYCResp
    {
      Status:string  ;
      StatusMessage:string ;
      CKYCNumber:string ='';
      DOB : string = '';
      Name : string = '';
      LastName : string = '';
      MobileNo : string = '';
      Emailid : string = '';
      Address1 : string = '';
    }

export class DigitCKYCModel
{
  ckycReferenceDocId:string  ;
  ckycReferenceNumber:string ;
  dateOfBirth:string ;
}
export class InsurerCompany{
  CompanyId: Number;
  CompanyName: String;
}
export class RliNomineeDetails
  {
    IsNomineeSameasCommAddr: boolean ;
    NomineeName:string ;
    NomineePincode:Number ;
    NomineeDOB: string;
    NomineeRelationWithProposer: string;
    NomineeAddress1: string;
    NomineeAddress2: string;
    NomineeArea: string;
    AppointeeName:string=null;
    AppointeeDOB:string='';
    MobileNo:string='';
    Age: Number = 0;
  }