import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from '../health/services/healthservices.service';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthenticationService} from 'src/app/health/services/authentication.service'; 
import { EncrDecrService } from '../health/services/EncrDecrService'; 
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import { User ,UserAuthentication} from 'src/app/common/Models/userDetail';
import { token } from 'src/app/common/Models/token';
import { GlobalService } from 'src/app/global.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent_POSP implements OnInit {
  token:token;
  ErrorHandle:ErrorHandle;
  isLogedUser:boolean=false;
  constructor(private service: HealthservicesService, private routerActive:ActivatedRoute,private globalService:GlobalService,
    private EncrDecr: EncrDecrService,private authService:AuthenticationService,private modalService: NgbModal) { }
  userProfileList: any;
  userProfileData: any;
  dashboard: any;
  userBankData: any;
  closeResult: string = '';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userId: string = "";
  remark: string = "remark";
  approved: string = "";
  rejected: string = "";
  onhold: string = "";
  totalPOS: string = "";
  imagePath: any;

  ngOnInit(): void {
    this.imagePath = this.service.imagePath;
    this.userProfileData = {
      AadharImageBack: "",
      AadharImageFront: "",
      AadharNo: "",
      Address1: "",
      Address2: "",
      AlternateNumber: "",
      City: "",
      CreatedBy: "",
      CreatedByIP: "",
      CreatedDate: "",
      DOB: "",
      EducationalImage: "",
      EducationalQualification: "",
      Emailid: "",
      FirstName: "",
      GSTINNumber: "",
      Gender: "",
      IsActive: "",
      LastName: "",
      MaritialStatus: "",
      MiddleName: "",
      ModifyBy: "",
      ModifyByIP: "",
      ModifyDate: "",
      PANImagePath: "",
      PANNumber: "",
      PinCode: "",
      ProfileImagePath: "",
      RequestId: "",
      State: "",
      Status: "",
      UserId: "",
      UserMasterId: "",
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };
debugger;
    if(sessionStorage.getItem('UserId')==null)
    {
    let getValues=this.routerActive.snapshot.queryParamMap.get('userid');
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', getValues);
      var data = JSON.parse(decrypted).split('|');
      // console.log(data);
      // alert(data);
      let vUserAuthentication = new UserAuthentication();
        vUserAuthentication.UserName=data[0];
        vUserAuthentication.Password=data[1];
        this.authService.getAuthentication(vUserAuthentication).subscribe((data: token)=>{          
          this.token=data;
          if(this.token.access_token !==''){             
              sessionStorage.setItem('access_token',this.token.access_token);
              //get user 
              this.authService.geUser(vUserAuthentication.UserName)
              .subscribe((data: User[]) =>{ 
                if(data.length>0){
                this.isLogedUser=true;  
                this.globalService.isLogin=true;  
                this.globalService.userName='Welcome,'+data[0].UserName;  
                if(sessionStorage.getItem('UserId')==null)
                    window.location.reload();
                sessionStorage.setItem('UserId',data[0].UserId.toString()); 
                sessionStorage.setItem('userName',data[0].UserName);
                sessionStorage.setItem('email',data[0].Email);
                sessionStorage.setItem('mobile',data[0].Mobile);
                if(data[0].UserName ==""){        
                  Swal.fire('Userid and password not matched', '', 'error');
                }
              }else{
                Swal.fire('Userid and password not matched', '', 'error');
              }
              },((err:ErrorHandle) => {
                this.ErrorHandle=err;    
                Swal.fire(this.ErrorHandle.statusText, '', 'error');  
            }));
          }
          else{              
              Swal.fire('Some error.', '', 'error');
          }         
          
        },((err:ErrorHandle) => {
          this.ErrorHandle=err;       
           if(this.ErrorHandle.statusText=="Bad Request"){
             Swal.fire('Userid and password are incorrect.', '', 'error');
           }
      }));
      localStorage.setItem('UserId',vUserAuthentication.UserName); 
    }

    this.getPendingRequest();
    this.GenerateDashboard();
  }
  getPendingRequest() {
    this.service.Select_UserProfile().subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userProfileList = data;
        this.dtTrigger.next();
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  open(content: any, data: any) {
    debugger;
    this.userId = data.UserId;
    let result = this.getUserProfileDetails(this.userId);
    this.getBankDetails(this.userId);

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getUserProfileDetails(userid: string) {
    var val = {
      Criteria: "UserId",
      Value: userid
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userProfileData = data[0];
        return this.userProfileData;
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  getUserPANDetails(userid: string) {
    var val = {
      Criteria: "UserId",
      Value: userid
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userProfileData = data[0];
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  getBankDetails(userid: string) {
    var val = {
      Criteria: "CustomerId",
      Value: userid
    }
    this.service.Select_UserBankDetailBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userBankData = data[0];
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  submitPersonalDataStatus() {
    debugger;
    var val = {
      CreatedDate: null,
      ModifyDate: null,
      IsActive: true,
      Userid: this.userId,
      Status: "Active",
      DocumentType: "User Profile",
      ValidOrNot: "User Verified",
      Remarks: this.remark,
      CreatedBy: "",
      CreatedByIP: "",
      ModifyBy: "",
      ModifyByIP: ""
    }
    this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        Swal.fire("", "Status Updated Successfully", "success");
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });
  }
  GenerateDashboard() {
    var val = {
      Criteria: "",
      Value: ""
    }
    this.service.GenerateDashboard(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.dashboard = data
        for (let i = 0; i < data.length; i++) {
          if (data[i].Status == "User Verified") {
            this.approved = data[i].NoOfUsers;
          }
          else if (data[i].Status == "User Not Verified") {
            this.rejected = data[i].NoOfUsers;
          }
          else {
            this.onhold = data[i].NoOfUsers;
          }
        }
        this.totalPOS = data[0].TotalCount;
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });
  }

  submitPANDataStatus() {
    var val = {
      CreatedDate: null,
      ModifyDate: null,
      IsActive: true,
      Userid: this.userId,
      Status: "Active",
      DocumentType: "PAN",
      ValidOrNot: "User Verified",
      Remarks: this.remark,
      CreatedBy: "",
      CreatedByIP: "",
      ModifyBy: "",
      ModifyByIP: ""
    }
    this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        Swal.fire("", "Status Updated Successfully", "success");
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });
  }
  submitAadharDataStatus() {
    var val = {
      CreatedDate: null,
      ModifyDate: null,
      IsActive: true,
      Userid: this.userId,
      Status: "Active",
      DocumentType: "Aadhar",
      ValidOrNot: "User Verified",
      Remarks: this.remark,
      CreatedBy: "",
      CreatedByIP: "",
      ModifyBy: "",
      ModifyByIP: ""
    }
    this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        Swal.fire("", "Status Updated Successfully", "success");
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });
  }
  submitEducationDataStatus() {
    var val = {
      CreatedDate: null,
      ModifyDate: null,
      IsActive: true,
      Userid: this.userId,
      Status: "Active",
      DocumentType: "Educational",
      ValidOrNot: "User Verified",
      Remarks: this.remark,
      CreatedBy: "",
      CreatedByIP: "",
      ModifyBy: "",
      ModifyByIP: ""
    }
    this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        Swal.fire("", "Status Updated Successfully", "success");
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });
  }
  submitBankDataStatus() {
    var val = {
      CreatedDate: null,
      ModifyDate: null,
      IsActive: true,
      Userid: this.userId,
      Status: "Active",
      DocumentType: "Bank",
      ValidOrNot: "User Verified",
      Remarks: this.remark,
      CreatedBy: "",
      CreatedByIP: "",
      ModifyBy: "",
      ModifyByIP: ""
    }
    this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        Swal.fire("", "Status Updated Successfully", "success");
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });
  }
}
