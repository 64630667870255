import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
//import { DateFormat } from "../../common/date-format";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { Router } from '@angular/router';
import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import { Subject } from 'rxjs';
import { AppSettings } from 'src/app/globals';
import { AuthenticationService } from '../../health/services/authentication.service';
import { User } from '../../common/Models/userDetail';
import { EncrDecrService } from '../../health/services/EncrDecrService';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-myinspection',
  templateUrl: './myinspection.component.html',
  styleUrls: ['./myinspection.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
    ,
     { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class MyinspectionComponent implements OnInit {

  UserId: number;
  QuotesList = [];
  QuotesListMotor = [];
  dtTrigger: Subject<any> = new Subject();
  dtTriggerMotor: Subject<any> = new Subject();
  dtOptionhlth: DataTables.Settings = {};
  dtOptions: DataTables.Settings = {};

  constructor(private healthMasterService: HealthservicesService, private router: Router, private globalService: GlobalService,
    private authService: AuthenticationService, private EncrDecr: EncrDecrService
    ) { 
   // this.globalService.lendingpage = true;
    if (sessionStorage.getItem('UserId') != null) {
      this.globalService.lendingpage = true;
      this.UserId = parseInt(sessionStorage.getItem('UserId'));
    } else {
      this.router.navigate(['/login']);
    }

  }
    
  ngOnInit() {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25],
      processing: true,
      order:[0, 'desc']
    };

    this.GetInspectionList();
  }

  GetInspectionList() {
    debugger;
   // $('#gridHealth').DataTable().destroy();
    this.healthMasterService.GetInspectionList(this.UserId).subscribe((data: any) => {
      debugger
      this.QuotesList = data.Table;
      this.dtTrigger.next();
    });
  }

  goPayment(item:any){
    debugger;
   
  //  if(this.proposalForm.controls['DateOfBirth'].value==null){
  //    this.proposalForm.controls['DateOfBirth'].setValue("");
  //  }
  //  if(this.proposalForm.controls['DateOfBirth'].value==''){
  //    Swal.fire('Please enter Date Of Birth', '', 'error');
  //    return false;
  //  }


   if(item.ProposalId !=null && item.ProposalId !=""){
     if(item.InspectionStatus=='Approved'){
      let paymentUrl=AppSettings.DomainUrlMotorCarInspection+'motorproposal/Processproposal?ProposalId='+item.ProposalId+'&ProductId='+item.ProductId+'&sessionId='+item.SessionId;
      //window.open(paymentUrl, '_blank');
      window.location.href=paymentUrl;
     }
     
   }
  }


}
