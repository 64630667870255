import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { isNull } from 'util';
import { HealthservicesService } from '../services/healthservices.service';
declare var $: any;
@Component({
  selector: 'app-add-pos-details',
  templateUrl: './add-pos-details.component.html',
  styleUrls: ['./add-pos-details.component.css']
})
export class AddPosDetailsComponent implements OnInit {

  constructor(private service: HealthservicesService, private formBuilder: FormBuilder, private route: Router) {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }
  loadAPI: Promise<any>;
  tab_1: any;
  tab_2: any;
  tab_3: any;
  tab_4: any;
  tab_5: any;
  tab_6: any;
  tab_7: any;
  UserId: any = "";
  userData: any;
  userProfileList: any;
  userProfileData: any;
  userBankData: any;
  formData = new FormData();
  vformData = new FormData();
  filecount: any = "";
  fileData: any = "";
  AlternateNumber: string = "";
  MaritialStatus: string = "";
  PANCardNumber: any = localStorage.getItem("PANNumber");
  DateOfBirth: string = "";
  AadharCardNumber: string = "";
  AadharAddress: string = "";
  Pincode: any = localStorage.getItem("Pincode");
  City: string = "";
  State: string = "";
  AccountHolderName: string = "";
  AccountNumber: string = "";
  BankBranchName: string = "";
  BankName: string = "";
  IFSCCode: string = "";
  ChqImg: string = "";
  CustomerId: string = "";
  EducationalQualification: string = "";
  EducationalProof: string = "";
  PersonalForm: FormGroup = new FormGroup({});
  PANForm: FormGroup = new FormGroup({});
  AdharForm: FormGroup = new FormGroup({});
  BankForm: FormGroup = new FormGroup({});
  EducationalForm: FormGroup = new FormGroup({});
  stateList: any;
  submitted = false;
  imageSrc: any = "";
  ApproveStatus: string = "";
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userStatus: any;
  checkApplicationStatus: boolean;
  userProfileImageSrc: string;
  userBankImageSrc: string;
  userAadharImageSrc: string;
  userEducationImageSrc: string;
  acceptOrReject: boolean;
  errorMsg: boolean;
  ProfileImgNm: string="";
  PANImgNm: string="";
  AADHARImgNm: string="";
  BANKImgNm: string="";
  EducationalImgNm: string="";
  imagePath: any;
  ApplicationStatus: string="";
  UserSuccess:boolean=false;

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = ["../../../assets/js/jquery-ui.min.js"];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }

  ngOnInit(): void {debugger;
    this.imagePath = this.service.imagePath;
    this.checkApplicationStatus = false;
    this.UserId = localStorage.getItem("UserId");
    this.PersonalForm = this.formBuilder.group(
      {
        MaritialStatus: ['', [Validators.required]],
        AlternateNumber: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        ProfileImg: ['']
      });
    this.PANForm = this.formBuilder.group(
      {
        PANCardNumber: ['', [Validators.required, Validators.pattern('[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}')]],
        DateOfBirth: ['', [Validators.required]],
        PANImg: ['']
      });
    this.AdharForm = this.formBuilder.group(
      {
        AadharCardNumber: ['', [Validators.required, Validators.pattern("^[0-9]{4}[0-9]{4}[0-9]{4}$")]],
        AadharAddress: ['', [Validators.required]],
        Pincode: ['', [Validators.required]],
        City: ['', [Validators.required]],
        State: ['', [Validators.required]],
        AadharImageFront: [''],
        AadharImageBack: [''],
      });
    this.BankForm = this.formBuilder.group(
      {
        AccountNumber: ['', [Validators.required, Validators.pattern("[0-9]{9,18}")]],
        IFSCCode: ['', [Validators.required, Validators.pattern("^[a-zA-Z]{4}0[a-zA-Z0-9]{6}$")]],
        BankName: ['', [Validators.required]],
        BankBranchName: ['', [Validators.required]],
        AccountHolderName: ['', [Validators.required]],
        ChqImg: ['']
      });
    this.EducationalForm = this.formBuilder.group(
      {
        EducationalQualification: ['', [Validators.required]],
        EducationalProof: ['']

      });
    this.tab_1 = true;
    var val = {
      Criteria: "UserId",
      Value: localStorage.getItem("UserId")
    }
    this.userData = {
      AadharImageBack: "",
      AadharImageFront: "",
      AadharNo: "",
      Address1: "",
      Address2: "",
      AlternateNumber: "",
      City: "",
      CreatedBy: "",
      CreatedByIP: "",
      CreatedDate: "",
      DOB: "",
      EducationalImage: "",
      EducationalQualification: "",
      Emailid: "",
      FirstName: "",
      GSTINNumber: "",
      Gender: "",
      IsActive: "",
      LastName: "",
      MaritialStatus: "",
      MiddleName: "",
      ModifyBy: "",
      ModifyByIP: "",
      ModifyDate: "",
      PANImagePath: "",
      PANNumber: "",
      PinCode: "",
      ProfileImagePath: "",
      RequestId: "",
      State: "",
      Status: "",
      UserId: "",
      UserMasterId: "",
    }

    $('#PersonalDetails').css("pointer-events", "visible");
    $('#PANDetails').css("pointer-events", "none");
    $('#AadharDetails').css("pointer-events", "none");
    $('#BankDetails').css("pointer-events", "none");
    $('#EducationDetails').css("pointer-events", "none");
    $('#Verification').css("pointer-events", "none");
    $('#ApplicationStatus').css("pointer-events", "none");

    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      this.userData = data[0];
      // this.activate_tab1();
      let Status = data[0].Status;
      this.ApplicationStatus=Status==null?'':Status;
      let Stage = data[0].Stage;
      if (Status != "" && Status != null){
        this.activate_tab7();
        if(Status=='User Verified'){
          this.UserSuccess=true;
        }
        else{
          this.UserSuccess=false;
        }
        
      }
      else if (Stage == "Educational") {
        this.activate_tab7();
      }
      else if (Stage == "User Profile") {
        this.activate_tab1();
      }
      else if (Stage == "PAN") {
        this.activate_tab2();
      }
      else if (Stage == "Aadhar") {
        this.activate_tab3();
      }
      else if (Stage == "Bank") {
        this.activate_tab4();
      }
      else {
        this.activate_tab1();
      }

      

    });

  }
  get f(): { [key: string]: AbstractControl } {
    return this.PersonalForm.controls;
  }
  get p(): { [key: string]: AbstractControl } {
    return this.PANForm.controls;
  }
  get a(): { [key: string]: AbstractControl } {
    return this.AdharForm.controls;
  }
  get b(): { [key: string]: AbstractControl } {
    return this.BankForm.controls;
  }
  get e(): { [key: string]: AbstractControl } {
    return this.EducationalForm.controls;
  }
  activate_tab1() {
    debugger;
    this.PersonalForm.reset();
    this.errorMsg = false;
    var PANDetails = document.getElementById("PANDetails");
    PANDetails.classList.remove("ui-tabs-active");
    var PersonalDetails = document.getElementById("PersonalDetails");
    PersonalDetails.classList.add("ui-tabs-active");
    var AadharDetails = document.getElementById("AadharDetails");
    AadharDetails.classList.remove("ui-tabs-active");
    var BankDetails = document.getElementById("BankDetails");
    BankDetails.classList.remove("ui-tabs-active");
    var EducationDetails = document.getElementById("EducationDetails");
    EducationDetails.classList.remove("ui-tabs-active");
    var Verification = document.getElementById("Verification");
    Verification.classList.remove("ui-tabs-active");
    var ApplicationStatus = document.getElementById("ApplicationStatus");
    ApplicationStatus.classList.remove("ui-tabs-active");
    if(this.ProfileImgNm==''){
      this.PersonalForm.get('ProfileImg').setValidators(Validators.required)
    }
    else{
      this.PersonalForm.get('ProfileImg').clearValidators();
    }
    const fileInput = document.querySelector('input[type="file"]');
    // fileInput.files = localStorage.getItem('file');

    // if(localStorage.getItem('file')!=''){
    //   this.ProfileImgNm=localStorage.getItem('file');
    //   // $('#imag').val(this.ProfileImgNm);
    //   // $('#profilefilelbl').text(this.ProfileImgNm);
    //   // document.getElementById('#profilefilelbl').innerHTML = this.ProfileImgNm;
    //   this.PersonalForm.value.ProfileImg=localStorage.getItem('file');
    // }
    $('#PersonalDetails').css("pointer-events", "visible");
    $('#PANDetails').css("pointer-events", "none");
    $('#AadharDetails').css("pointer-events", "none");
    $('#BankDetails').css("pointer-events", "none");
    $('#EducationDetails').css("pointer-events", "none");
    $('#Verification').css("pointer-events", "none");
    if(this.ApplicationStatus!=""){
      $('#ApplicationStatus').css("pointer-events", "visible");
    }
    else {
      $('#ApplicationStatus').css("pointer-events", "none");
    }
    
    this.tab_1 = true;
    this.tab_2 = false;
    this.tab_3 = false;
    this.tab_4 = false;
    this.tab_5 = false;
    this.tab_6 = false;
    this.tab_7 = false;
  }
  activate_tab2() {
    debugger;
    this.PANForm.reset();
    this.PANCardNumber=this.userData.PANNumber;
    // this.submitted = true;
    // if (this.PersonalForm.invalid) {
    //   return;
    // }
    this.errorMsg = false;
    var PANDetails = document.getElementById("PANDetails");
    PANDetails.classList.add("ui-tabs-active");
    var PersonalDetails = document.getElementById("PersonalDetails");
    PersonalDetails.classList.remove("ui-tabs-active");
    var AadharDetails = document.getElementById("AadharDetails");
    AadharDetails.classList.remove("ui-tabs-active");
    var BankDetails = document.getElementById("BankDetails");
    BankDetails.classList.remove("ui-tabs-active");
    var EducationDetails = document.getElementById("EducationDetails");
    EducationDetails.classList.remove("ui-tabs-active");
    var Verification = document.getElementById("Verification");
    Verification.classList.remove("ui-tabs-active");
    var ApplicationStatus = document.getElementById("ApplicationStatus");
    ApplicationStatus.classList.remove("ui-tabs-active");
    if(this.PANImgNm==''){
      this.PANForm.get('PANImg').setValidators(Validators.required)
    }
    else{
      this.PANForm.get('PANImg').clearValidators();
    }
    // if(localStorage.getItem('PANfile')!=''){
    //   this.PANImgNm=localStorage.getItem('PANfile');
    //   this.PersonalForm.value.PANImg=localStorage.getItem('PANfile');
    // }

    $('#PersonalDetails').css("pointer-events", "none");
    $('#PANDetails').css("pointer-events", "visible");
    $('#AadharDetails').css("pointer-events", "none");
    $('#BankDetails').css("pointer-events", "none");
    $('#EducationDetails').css("pointer-events", "none");
    $('#Verification').css("pointer-events", "none");
    if(this.ApplicationStatus!=""){
      $('#ApplicationStatus').css("pointer-events", "visible");
    }
    else {
      $('#ApplicationStatus').css("pointer-events", "none");
    }

    this.tab_1 = false;
    this.tab_2 = true;
    this.tab_3 = false;
    this.tab_4 = false;
    this.tab_5 = false;
    this.tab_6 = false;
    this.tab_7 = false;
  }
  activate_tab3() {
    debugger;
    this.AdharForm.reset();
    // if (isNull(this.Pincode)) {
    //   $("#pincode").prop('disabled', false);
    // }
    // else {
    //   $("#pincode").css("pointer-events", "none");
    // }
    this.errorMsg = false;
    var PANDetails = document.getElementById("PANDetails");
    PANDetails.classList.remove("ui-tabs-active");
    var PersonalDetails = document.getElementById("PersonalDetails");
    PersonalDetails.classList.remove("ui-tabs-active");
    var AadharDetails = document.getElementById("AadharDetails");
    AadharDetails.classList.add("ui-tabs-active");
    var BankDetails = document.getElementById("BankDetails");
    BankDetails.classList.remove("ui-tabs-active");
    var EducationDetails = document.getElementById("EducationDetails");
    EducationDetails.classList.remove("ui-tabs-active");
    var Verification = document.getElementById("Verification");
    Verification.classList.remove("ui-tabs-active");
    var ApplicationStatus = document.getElementById("ApplicationStatus");
    ApplicationStatus.classList.remove("ui-tabs-active");
    if(this.AADHARImgNm==''){
      this.AdharForm.get('AadharImageFront').setValidators(Validators.required)
    }
    else{
      this.AdharForm.get('AadharImageFront').clearValidators();
    }
    // if(localStorage.getItem('Aadharfile')!=''){
    //   this.AADHARImgNm=localStorage.getItem('Aadharfile');
    //   this.PersonalForm.value.AadharImageFront=localStorage.getItem('Aadharfile');
    // }
    $('#PersonalDetails').css("pointer-events", "none");
    $('#PANDetails').css("pointer-events", "none");
    $('#AadharDetails').css("pointer-events", "visible");
    $('#BankDetails').css("pointer-events", "none");
    $('#EducationDetails').css("pointer-events", "none");
    $('#Verification').css("pointer-events", "none");
    if(this.ApplicationStatus!=""){
      $('#ApplicationStatus').css("pointer-events", "visible");
    }
    else {
      $('#ApplicationStatus').css("pointer-events", "none");
    }
    this.tab_1 = false;
    this.tab_2 = false;
    this.tab_3 = true;
    this.tab_4 = false;
    this.tab_5 = false;
    this.tab_6 = false;
    this.tab_7 = false;
  }
  activate_tab4() {
    this.BankForm.reset();
    this.errorMsg = false;
    var PANDetails = document.getElementById("PANDetails");
    PANDetails.classList.remove("ui-tabs-active");
    var PersonalDetails = document.getElementById("PersonalDetails");
    PersonalDetails.classList.remove("ui-tabs-active");
    var AadharDetails = document.getElementById("AadharDetails");
    AadharDetails.classList.remove("ui-tabs-active");
    var BankDetails = document.getElementById("BankDetails");
    BankDetails.classList.add("ui-tabs-active");
    var EducationDetails = document.getElementById("EducationDetails");
    EducationDetails.classList.remove("ui-tabs-active");
    var Verification = document.getElementById("Verification");
    Verification.classList.remove("ui-tabs-active");
    var ApplicationStatus = document.getElementById("ApplicationStatus");
    ApplicationStatus.classList.remove("ui-tabs-active");
    if(this.BANKImgNm==''){
      this.BankForm.get('ChqImg').setValidators(Validators.required)
    }
    else{
      this.BankForm.get('ChqImg').clearValidators();
    }
    // if(localStorage.getItem('Bankfile')!=''){
    //   this.BANKImgNm=localStorage.getItem('Bankfile');
    //   this.PersonalForm.value.Bankfile=localStorage.getItem('Bankfile');
    // }
    $('#PersonalDetails').css("pointer-events", "none");
    $('#PANDetails').css("pointer-events", "none");
    $('#AadharDetails').css("pointer-events", "none");
    $('#BankDetails').css("pointer-events", "visible");
    $('#EducationDetails').css("pointer-events", "none");
    $('#Verification').css("pointer-events", "none");
    if(this.ApplicationStatus!=""){
      $('#ApplicationStatus').css("pointer-events", "visible");
    }
    else {
      $('#ApplicationStatus').css("pointer-events", "none");
    }
    this.tab_1 = false;
    this.tab_2 = false;
    this.tab_3 = false;
    this.tab_4 = true;
    this.tab_5 = false;
    this.tab_6 = false;
    this.tab_7 = false;
  }
  activate_tab5() {
    this.EducationalForm.reset();
    this.errorMsg = false;
    var PANDetails = document.getElementById("PANDetails");
    PANDetails.classList.remove("ui-tabs-active");
    var PersonalDetails = document.getElementById("PersonalDetails");
    PersonalDetails.classList.remove("ui-tabs-active");
    var AadharDetails = document.getElementById("AadharDetails");
    AadharDetails.classList.remove("ui-tabs-active");
    var BankDetails = document.getElementById("BankDetails");
    BankDetails.classList.remove("ui-tabs-active");
    var EducationDetails = document.getElementById("EducationDetails");
    EducationDetails.classList.add("ui-tabs-active");
    var Verification = document.getElementById("Verification");
    Verification.classList.remove("ui-tabs-active");
    var ApplicationStatus = document.getElementById("ApplicationStatus");
    ApplicationStatus.classList.remove("ui-tabs-active");
    if(this.EducationalImgNm==''){
      this.EducationalForm.get('EducationalProof').setValidators(Validators.required)
    }
    else{
      this.EducationalForm.get('EducationalProof').clearValidators();
    }
    // if(localStorage.getItem('Educationalfile')!=''){
    //   this.EducationalImgNm=localStorage.getItem('Educationalfile');
    //   this.PersonalForm.value.EducationalImage=localStorage.getItem('Educationalfile');
    // }
    $('#PersonalDetails').css("pointer-events", "none");
    $('#PANDetails').css("pointer-events", "none");
    $('#AadharDetails').css("pointer-events", "none");
    $('#BankDetails').css("pointer-events", "none");
    $('#EducationDetails').css("pointer-events", "visible");
    $('#Verification').css("pointer-events", "none");
    if(this.ApplicationStatus!=""){
      $('#ApplicationStatus').css("pointer-events", "visible");
    }
    else {
      $('#ApplicationStatus').css("pointer-events", "none");
    }
    this.tab_1 = false;
    this.tab_2 = false;
    this.tab_3 = false;
    this.tab_4 = false;
    this.tab_5 = true;
    this.tab_6 = false;
    this.tab_7 = false;
  }
  activate_tab6() {
    this.errorMsg = false;
    var PANDetails = document.getElementById("PANDetails");
    PANDetails.classList.remove("ui-tabs-active");
    var PersonalDetails = document.getElementById("PersonalDetails");
    PersonalDetails.classList.remove("ui-tabs-active");
    var AadharDetails = document.getElementById("AadharDetails");
    AadharDetails.classList.remove("ui-tabs-active");
    var BankDetails = document.getElementById("BankDetails");
    BankDetails.classList.remove("ui-tabs-active");
    var EducationDetails = document.getElementById("EducationDetails");
    EducationDetails.classList.remove("ui-tabs-active");
    var Verification = document.getElementById("Verification");
    Verification.classList.add("ui-tabs-active");
    var ApplicationStatus = document.getElementById("ApplicationStatus");
    ApplicationStatus.classList.remove("ui-tabs-active");

    // if(localStorage.getItem('file')!=''){
    //   this.AADHARImgNm=localStorage.getItem('file');
    //   this.PersonalForm.value.ProfileImg=localStorage.getItem('file');
    // }
    $('#PersonalDetails').css("pointer-events", "none");
    $('#PANDetails').css("pointer-events", "none");
    $('#AadharDetails').css("pointer-events", "none");
    $('#BankDetails').css("pointer-events", "none");
    $('#EducationDetails').css("pointer-events", "none");
    $('#Verification').css("pointer-events", "visible");
    if(this.ApplicationStatus!=""){
      $('#ApplicationStatus').css("pointer-events", "visible");
    }
    else {
      $('#ApplicationStatus').css("pointer-events", "none");
    }
    this.tab_1 = false;
    this.tab_2 = false;
    this.tab_3 = false;
    this.tab_4 = false;
    this.tab_5 = false;
    this.tab_6 = true;
    this.tab_7 = false;
  }
  activate_tab7() {
    this.errorMsg = false;
    var PANDetails = document.getElementById("PANDetails");
    PANDetails.classList.remove("ui-tabs-active");
    var PersonalDetails = document.getElementById("PersonalDetails");
    PersonalDetails.classList.remove("ui-tabs-active");
    var AadharDetails = document.getElementById("AadharDetails");
    AadharDetails.classList.remove("ui-tabs-active");
    var BankDetails = document.getElementById("BankDetails");
    BankDetails.classList.remove("ui-tabs-active");
    var EducationDetails = document.getElementById("EducationDetails");
    EducationDetails.classList.remove("ui-tabs-active");
    var Verification = document.getElementById("Verification");
    Verification.classList.remove("ui-tabs-active");
    var ApplicationStatus = document.getElementById("ApplicationStatus");
    ApplicationStatus.classList.add("ui-tabs-active");

    // if(localStorage.getItem('file')!=''){
    //   this.AADHARImgNm=localStorage.getItem('file');
    //   this.PersonalForm.value.ProfileImg=localStorage.getItem('file');
    // }
    $('#PersonalDetails').css("pointer-events", "none");
    $('#PANDetails').css("pointer-events", "none");
    $('#AadharDetails').css("pointer-events", "none");
    $('#BankDetails').css("pointer-events", "none");
    $('#EducationDetails').css("pointer-events", "none");
    $('#Verification').css("pointer-events", "none");
    $('#ApplicationStatus').css("pointer-events", "visible");
    this.tab_1 = false;
    this.tab_2 = false;
    this.tab_3 = false;
    this.tab_4 = false;
    this.tab_5 = false;
    this.tab_6 = false;
    this.tab_7 = true;
    this.SelectUserStatusById();
  }
  changeProfileImage(fileInput: any) {
    debugger;
    this.formData = new FormData();
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      if(fileInput.target.files.length > 0) 
      {
        // console.log(fileInput.target.files[0].name);
        // localStorage.setItem('file',fileInput.target.files[0].name);
        this.ProfileImgNm=fileInput.target.files[0].name;
      }
      
      this.formData.append('file', this.fileData);

      this.vformData = this.formData;
    }
    this.formData.append('UserId', this.UserId);
    this.formData.append('GSTINNumber', "");
    this.formData.append('FirstName', this.userData.FirstName);
    this.formData.append('LastName', this.userData.LastName);
    this.formData.append('MiddleName', this.userData.MiddleName);
    this.formData.append('MaritialStatus', this.MaritialStatus)
    this.formData.append('AlternateNumber', this.AlternateNumber)
    this.vformData = this.formData;

  }
  // changeImage(e: any) {
  //   debugger;
  //   // add your logic to decide which image control you'll use
  //   var imgControlName = "#ImgPreview";
  //   this.readURL(e, imgControlName);
  //   $('.proimg').addClass('it');
  //   $('.prormv').addClass('rmv');
  // }

  removeImage(e: any) {
    e.preventDefault();
    $("#imag").val("");
    $("#ImgPreview").attr("src", "");
    $('.proimg').removeClass('it');
    $('.prormv').removeClass('rmv');
  };
  //image preview
  readURL(event: any): void {
    debugger;
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(file);
    }
  }
  UpdatePersonalDetails() {
    debugger;
    this.submitted = true;
    this.errorMsg = false;
    if (this.PersonalForm.invalid) {
      this.errorMsg = true;
      this.vformData.delete('MaritialStatus');
      this.vformData.delete('AlternateNumber');
      return;
    }
    if (this.vformData.get('MaritialStatus') == null) {
      this.vformData.append('MaritialStatus', this.MaritialStatus)
    }
    if (this.vformData.get('AlternateNumber') == null) {
      this.vformData.append('AlternateNumber', this.AlternateNumber)
    }



    this.service.UpdateUserProfile(this.vformData).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        Swal.fire("", "Personal details added successfully", "success");
        if(this.ApplicationStatus==''){
          this.activate_tab2();
        }
        
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab2();
      }
    });
  }
  changePANCardImage(fileInput: any) {
    debugger;
    this.formData = new FormData;
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      if(fileInput.target.files.length > 0) 
      {
        // console.log(fileInput.target.files[0].name);
        // localStorage.setItem('PANfile',fileInput.target.files[0].name);
        this.PANImgNm=fileInput.target.files[0].name;
      }
      this.formData.append('file', this.fileData);

      this.vformData = this.formData;
    }

    this.formData.append('UserId', this.UserId);
    this.formData.append('PANNumber', this.PANCardNumber)
    this.formData.append('DOB', this.DateOfBirth)

    this.vformData = this.formData;
  }
  UpdatePANDetails() {
    debugger;
    this.submitted = true;
    this.errorMsg = false;
    if (this.PANForm.invalid) {
      this.errorMsg = true;
      this.formData.delete('PANNumber')
      this.formData.delete('DOB')
      return;
    }
    if (this.vformData.get('PANNumber') == null) {
      this.vformData.append('PANNumber', this.PANCardNumber)
      localStorage.setItem("PANNumber", this.PANCardNumber);
    }
    if (this.vformData.get('DOB') == null) {
      this.vformData.append('DOB', this.DateOfBirth)
    }
    this.vformData.append('flag', 'create');
    this.service.CreateUserPANDetails(this.vformData).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        this.FetchStateCity();
        Swal.fire("", "PAN details added successfully", "success")
        if(this.ApplicationStatus==''){
          this.activate_tab3();
        }
        
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab3();
      }
    });
  }
  changeAadharImgFront(fileInput: any) {
    debugger;
    this.formData = new FormData;
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      if(fileInput.target.files.length > 0) 
      {
        // console.log(fileInput.target.files[0].name);
        // localStorage.setItem('Aadharfile',fileInput.target.files[0].name);
        this.AADHARImgNm=fileInput.target.files[0].name;
      }
      this.formData.append('Front', this.fileData);

      this.vformData = this.formData;
    }
  }
  changeAadharImgBack(fileInput: any) {
    debugger;
    // this.formData = new FormData;
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];

      this.formData.append('Back', this.fileData);

      this.vformData = this.formData;
    }

    this.formData.append('UserId', this.UserId);
    this.formData.append('AadharNo', this.AadharCardNumber)
    this.formData.append('Address1', this.AadharAddress)
    this.formData.append('City', this.City)
    this.formData.append('State', this.State)
    this.formData.append('Pincode', this.Pincode)

    this.vformData = this.formData;
  }
  updateUserAadharDetails() {
    debugger;
    this.submitted = true;
    this.errorMsg = false;
    if (this.AdharForm.invalid) {
      this.errorMsg = true;
      this.formData.delete('AadharNo')
      this.formData.delete('Address1')
      this.formData.delete('City')
      this.formData.delete('State')
      this.formData.delete('Pincode')
      return;
    }
    if (this.vformData.get('AadharNo') == null) {
      this.vformData.append('AadharNo', this.AadharCardNumber)
    }
    if (this.vformData.get('Address1') == null) {
      this.vformData.append('Address1', this.AadharAddress)
    }
    if (this.vformData.get('City') == null) {
      this.vformData.append('City', this.City)
    }
    if (this.vformData.get('State') == null) {
      this.vformData.append('State', this.State)
    }
    if (this.vformData.get('Pincode') == null) {
      this.vformData.append('Pincode', this.Pincode)
      localStorage.setItem("Pincode", this.Pincode)
    }
    this.vformData.append('flag', 'create');
    this.service.CreateUserAadharDetails(this.vformData).subscribe((data: any) => {
      debugger;

      if (data.Status == "SUCCESS") {
        Swal.fire("", "Aadhaar details added successfully", "success")
        if(this.ApplicationStatus==''){
          this.activate_tab4();
        }
        
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab4();
      }
    });
  }
  changeChequeImg(fileInput: any) {
    debugger;
    this.formData = new FormData;
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      if(fileInput.target.files.length > 0) 
      {
        // console.log(fileInput.target.files[0].name);
        // localStorage.setItem('Bankfile',fileInput.target.files[0].name);
        this.BANKImgNm=fileInput.target.files[0].name;
      }
      this.formData.append('file', this.fileData);

      this.vformData = this.formData;
    }

    this.formData.append('UserId', this.UserId);
    this.formData.append('AccountHolderName', this.AccountHolderName)
    this.formData.append('AccountNumber', this.AccountNumber)
    this.formData.append('BankBranchName', this.BankBranchName)
    this.formData.append('BankName', this.BankName)
    this.formData.append('CreatedBy', this.UserId)
    this.formData.append('CreatedByIP', "")
    this.formData.append('IFSCCode', this.IFSCCode)
    this.formData.append('IsActive', "true")
    this.formData.append('ModifyBy', "Admin")
    this.formData.append('ModifyByIP', "")
    this.formData.append('CustomerId', this.UserId)
    this.formData.append('Status', '1');

    this.vformData = this.formData;
  }
  updateUserBankDetails() {
    debugger;
    this.submitted = true;
    this.errorMsg = false;
    if (this.BankForm.invalid) {
      this.errorMsg = true;
      this.formData.delete('AccountHolderName')
      this.formData.delete('AccountNumber')
      this.formData.delete('BankBranchName')
      this.formData.delete('BankName')
      this.formData.delete('CreatedBy')
      this.formData.delete('CreatedByIP')
      this.formData.delete('IFSCCode')
      this.formData.delete('IsActive')
      this.formData.delete('ModifyBy')
      this.formData.delete('ModifyByIP')

      return;
    }
    if (this.vformData.get('AccountHolderName') == null) {
      this.vformData.append('AccountHolderName', this.AccountHolderName)
    }
    if (this.vformData.get('AccountNumber') == null) {
      this.vformData.append('AccountNumber', this.AccountNumber)
    }
    if (this.vformData.get('BankBranchName') == null) {
      this.vformData.append('BankBranchName', this.BankBranchName)
    }
    if (this.vformData.get('BankName') == null) {
      this.vformData.append('BankName', this.BankName)
    }
    if (this.vformData.get('CreatedBy') == null) {
      this.vformData.append('CreatedBy', this.UserId)
    }
    if (this.vformData.get('CreatedByIP') == null) {
      this.vformData.append('CreatedByIP', '')
    }
    if (this.vformData.get('IFSCCode') == null) {
      this.vformData.append('IFSCCode', this.IFSCCode)
    }
    if (this.vformData.get('IsActive') == null) {
      this.vformData.append('IsActive', 'true')
    }
    if (this.vformData.get('ModifyBy') == null) {
      this.vformData.append('ModifyBy', '')
    }
    if (this.vformData.get('ModifyByIP') == null) {
      this.vformData.append('ModifyByIP', '')
    }
    this.vformData.append('flag', 'create');
    this.service.CreateUserBankDetail(this.vformData).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        Swal.fire("", "Bank details added successfully", "success")
        if(this.ApplicationStatus==''){
          this.activate_tab5();
        }
        
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab5();
      }
    });
  }
  changeEducationalProof(fileInput: any) {
    debugger;
    this.formData = new FormData;
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      if(fileInput.target.files.length > 0) 
      {
        // console.log(fileInput.target.files[0].name);
        // localStorage.setItem('Educationalfile',fileInput.target.files[0].name);
        this.EducationalImgNm=fileInput.target.files[0].name;
      }
      this.formData.append('file', this.fileData);

      this.vformData = this.formData;
    }

    this.formData.append('UserId', this.UserId);
    this.formData.append('EducationalQualification', this.EducationalQualification)
    this.formData.append('EducationalProof', this.EducationalProof)
  }
  updateUserEducationalDetail() {
    debugger;
    this.submitted = true;
    this.errorMsg = false;
    if (this.EducationalForm.invalid) {
      this.errorMsg = true;
      this.formData.delete('EducationalQualification')
      this.formData.delete('EducationalProof')
      return;
    }
    if (this.vformData.get('EducationalQualification') == null) {
      this.vformData.append('EducationalQualification', this.EducationalQualification)
    }
    if (this.vformData.get('EducationalProof') == null) {
      this.vformData.append('EducationalProof', this.EducationalProof)
    }
    this.vformData.append('flag', 'create');
    Swal.fire({
      title: '',
      text: 'Have you confirmed all the details ?',

      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {

      if (result.isConfirmed) {
        this.service.CreateUserEducationalDetail(this.vformData).subscribe((data: any) => {
          debugger;
          if (data.Status == "SUCCESS") {
            Swal.fire("", "Education details added successfully", "success")
            if(this.ApplicationStatus==''){
              this.activate_tab6();
            }
            
            $('#PersonalDetails').css("pointer-events", "none");
            $('#PANDetails').css("pointer-events", "none");
            $('#AadharDetails').css("pointer-events", "none");
            $('#BankDetails').css("pointer-events", "none");
            $('#EducationDetails').css("pointer-events", "none");
          }
          else {
            Swal.fire("", data.ErrorDescription, "error");
            // this.activate_tab6();
          }
        });

      } else if (result.isDismissed) {
      }
    })

  }
  FetchStateCity() {debugger;
    this.service.FetchStateCity(this.Pincode).subscribe((data: any) => {
      debugger;
      if (data[0].Status == 'Success') {
        this.stateList = data[0].PostOffice;
        this.City = this.stateList[0].Name;
        this.State = this.stateList[0].State;
      }
      // if (data[0].IsActive == true) {
      //   // this.stateList = data[0].PostOffice;
      //   this.City = data[0].CityName;
      //   this.State = data[0].StateName;
      // }
    });
  }
  SelectUserStatusById() {
    var val = {
      Criteria: "UserId",
      Value: this.UserId
    }
    this.service.SelectUserStatusById(val).subscribe((data: any) => {
      debugger;
      this.userStatus = data;
      for (let i = 0; i < data.length; i++) {
        if (data[i].ValidOrNot == 'Valid') {
          this.acceptOrReject = true;
        }
        else {
          this.acceptOrReject = false;
          break;
        }
      }
      if (data.length > 0) {
        this.checkApplicationStatus = false;
      }
      else {
        this.checkApplicationStatus = true;
        if (data[0].ValidOrNot == 'Valid') {
          this.ApproveStatus = "Approved";
        }
        else if (data[0].ValidOrNot == 'Hold') {
          this.ApproveStatus = "Hold";
        }
        else {
          this.ApproveStatus = "Rejected";
        }
      }

    });
  }
  FetchDataForUpdate(documentType: any) {
    debugger;
    if (documentType == "User Profile") {
      this.getUserProfileDetails(this.UserId);
      this.activate_tab1();
    }
    else if (documentType == "PAN") {
      this.getUserPANDetails(this.UserId);
      this.activate_tab2();
    }
    else if (documentType == "Aadhar") {
      this.getUserProfileDetails(this.UserId);
      this.activate_tab3();
    }
    else if (documentType == "Bank") {
      this.getBankDetails(this.UserId);
      this.activate_tab4();
    }
    else if (documentType == "Educational") {
      this.getUserProfileDetails(this.UserId);
      this.activate_tab5();
    }
    else {
      this.activate_tab7();
    }
  }
  getUserProfileDetails(userid: string) {
    var val = {
      Criteria: "UserId",
      Value: userid
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        // this.userProfileData = data[0];
        this.MaritialStatus = data[0].MaritialStatus;
        this.AlternateNumber = data[0].AlternateNumber;
        this.AadharCardNumber = data[0].AadharNo;
        this.AadharAddress = data[0].Address1;
        this.Pincode = data[0].PinCode;
        this.City = data[0].City;
        this.State = data[0].State;
        this.EducationalQualification = data[0].EducationalQualification;
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  getUserPANDetails(userid: string) {
    var val = {
      Criteria: "UserId",
      Value: userid
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      alert(0);
      if (data.length > 0) {
        alert(1);
        this.PANCardNumber = data[0].PANNumber;
        this.DateOfBirth = data[0].DOB;
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  getBankDetails(userid: string) {
    var val = {
      Criteria: "CustomerId",
      Value: userid
    }
    this.service.Select_UserBankDetailBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userBankData = data[0];
        this.AccountNumber = data[0].AccountNumber;
        this.IFSCCode = data[0].IFSCCode;
        this.BankBranchName = data[0].BankBranchName;
        this.BankName = data[0].BankName;
        this.AccountHolderName = data[0].AccountHolderName;

      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
}

