import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalService } from '../global.service';
import { HealthservicesService } from '../health/services/healthservices.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import * as saveAs from 'file-saver';
import * as jQuery from 'jquery';
declare var $: any;

@Component({
  selector: 'app-apilog',
  templateUrl: './apilog.component.html',
  styleUrls: ['./apilog.component.css']
})
export class ApilogComponent implements OnInit {
  // @ViewChild(DataTableDirective)
  dtElement!: DataTableDirective;
  UserId: string;
  getApilogList = [];
  getApiErrorlogList = [];
  dtTrigger: Subject<any> = new Subject();
  dtTrigger1: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  constructor(private masterService: HealthservicesService, private router: Router,
    private formBuilder: FormBuilder,
    private globalService: GlobalService) {
    // $('#grid').DataTable().destroy();
    // $('#grid2').DataTable().destroy();
    if (localStorage.getItem('userName') != null) {
      this.UserId = localStorage.getItem('userName');
    } else {

      this.UserId = "";
    }
  }
  ngOnInit() {debugger;
    this.globalService.lendingpage=true;
    this.getDataList();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25],
      processing: true
    };
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25],
      processing: true
    };
  }

  getDataList() {
    debugger;
    let searchtext = "Buildplan";
    let Startdate = $("#txtFromDate").val();
    let EndDate = $("#txtToDate").val();
    let QuoteId = $("#txtQuoteId").val();
    // this.getApilogList=null;
    this.masterService.GetApilogDetailsList(this.UserId, QuoteId, Startdate, EndDate).subscribe((data: any[]) => {
      console.log(data);
      // this.rerender();
      this.getApilogList = data;
      this.dtTrigger.next();
      
    });
  }
  
  buttonInRowClick(QuoteGUID:any){debugger;
    $('#grid2').DataTable().destroy();
    this.masterService.GetErrorProductAPILogData(QuoteGUID).subscribe((data: any[]) => {
      console.log(data);
      // this.rerender();
      this.getApiErrorlogList = data;
      this.dtTrigger1.next();
      $("#insuranceModal").modal('show');
    });

  }

  DownloadQuote(LogId:any,quoteType:number){

    this.masterService.DownloadQuote(LogId,quoteType).subscribe((data: string) => {debugger;
      console.log(data);
      if(data!=null){debugger;
        this.SaveText(data,quoteType);
      }
      
    });

  }

  SaveText(data:any,quoteType:number) {
    let file = new Blob([data], { type: 'text/csv;charset=utf-8' });
    if(quoteType==1){
      saveAs(file, 'Request.txt');
    }else{
      saveAs(file, 'Response.txt');
    }
    
  }  

  CloseModal(){
    $("#insuranceModal").modal('hide');
  }

}
