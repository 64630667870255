import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from 'src/app/health/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
user:any;
  constructor(private authService:AuthenticationService) { }

  ngOnInit() {
    
  }

  getUser(){
    debugger;
    if(localStorage.getItem('userName')!=null){}
          alert(localStorage.getItem('userName'));
      this.authService.geUser(localStorage.getItem('userName')).subscribe(res=>{
          this.user=res;
      });
    } 

}
