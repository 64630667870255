import { ActivatedRoute, Router } from '@angular/router';
import { Component,ViewChild , OnInit, HostListener} from '@angular/core';
import {AuthenticationService} from 'src/app/health/services/authentication.service';
import { User ,UserAuthentication} from '../../../common/Models/userDetail';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import Swal from 'sweetalert2';
import { ErrorHandle } from '../../models/ErrorHandle';
import { token } from '../../Models/token';
import { HeaderComponent } from '../../header/header.component';
import { GlobalService } from 'src/app/global.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isShow: boolean;
  topPosToStartShowing = 100;
  show: boolean = false;
  code: string;
  @HostListener('window:scroll')
  checkScroll() {
      
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    console.log('[scroll]', scrollPosition);
    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
  
  title = '';
  isLogin:boolean; 
  @ViewChild(HeaderComponent,{static:false}) headerComponent : HeaderComponent;
  user:any;
  registerForm: FormGroup;
  loginForm:FormGroup;
    loading = false;
    submitted = false;  
    isLogedUser:boolean=false;

 isRegister:boolean;
 ErrorHandle:ErrorHandle;
 token:token;
 loginUser:User[];
 UserAuthentication: UserAuthentication;
  constructor( private globalService:GlobalService,private authService:AuthenticationService, private routerActive:ActivatedRoute,
    private formBuilder: FormBuilder,private router:Router  )
    {
      this.globalService.lendingpage=true;
    if(sessionStorage.getItem('userName')==null){      
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('mobile'); 
      this.globalService.isLogin=false; 
      this.isLogin=false;
      
    }else{
      this.globalService.isLogin=true;
      this.isLogin=true;
      this.globalService.userName= sessionStorage.getItem('userName');
      this.router.navigate(['/dashboard']);
      
      
    } 
    this.isLogin=true;
    this.isRegister=false; 
    
  }

  ngOnInit() {
    
    if(sessionStorage.getItem('email')!=null){
      this.authService.geUser(sessionStorage.getItem('email')).subscribe((res:User)=>{
        this.user=res[0].UserName;
      });
    }
    this.code = this.routerActive.snapshot.queryParamMap.get('code');
    
    this.registerForm = this.formBuilder.group({      
      RoleId: new FormControl(4),
      UserName: new FormControl(null, Validators.required),
      Email: new FormControl(null, [Validators.required, Validators.email]),
      Mobile: new FormControl(null, [Validators.required, Validators.pattern("[0-9]{10}")]),
      Password: new FormControl(null, [Validators.required, Validators.minLength(6)])
  });
  }
  get r() { return this.registerForm.controls; }
  userLogin(){    debugger;
    this.submitted = true;
        this.setUserCategoryValidators();
        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;        
        let vUserAuthentication = new UserAuthentication();
        vUserAuthentication.UserName=this.registerForm.value.Email;
        vUserAuthentication.Password=this.registerForm.value.Password;
        this.authService.getAuthentication(vUserAuthentication).subscribe((data: token)=>{          
          this.token=data;
          if(this.token.access_token !==''){
              this.loading = false;
              sessionStorage.setItem('access_token',this.token.access_token);
              if($("#remember-check").is(':checked')){
                localStorage.setItem('access_token',this.token.access_token); 
              }else{
                localStorage.removeItem('access_token');
              }
        
              //this.router.navigate(["/dashboard"]);
              this.GetUserDetails(this.registerForm.value.Email);
          }
          else{
              this.loading = false;
              Swal.fire('Userid And/Or password are incorrect. Please try again.', '', 'error');
          }         
          
        },((err:ErrorHandle) => {
          this.ErrorHandle=err;       
           if(this.ErrorHandle.statusText=="Bad Request"){
             Swal.fire('Userid And/Or password are incorrect. Please try again.', '', 'error');
           }
           else
           {
            Swal.fire('Userid And/Or password are incorrect. Please try again.', '', 'error');
           }
      }));
          //loginUser
  }
  setUserCategoryValidators() {
    const name = this.registerForm.get('UserName');
    const emailId = this.registerForm.get('Email');
    const password = this.registerForm.get('Password');
    const mobileNo = this.registerForm.get('Mobile');
       if (this.isLogin === true) {
          mobileNo.setValidators(null);
          name.setValidators(null);

          emailId.setValidators([Validators.required, Validators.email]);
          password.setValidators([Validators.required, Validators.minLength(6)]);
        }
        if (this.isRegister === true) {
          mobileNo.setValidators([Validators.required, Validators.pattern("[0-9]{10}") ]);
          name.setValidators([Validators.required]);
          emailId.setValidators([Validators.required, Validators.email]);
          password.setValidators([Validators.required , Validators.minLength(6)]);
        }
        name.updateValueAndValidity();
        mobileNo.updateValueAndValidity();   
        emailId.updateValueAndValidity();
        password.updateValueAndValidity();   

  }
  GetUserDetails(username){
    this.authService.geUser(username)
    .subscribe((data: User[]) =>{ 
      if(data.length>0){
        if(data[0].UserName !=""){        debugger;
          if(this.code=='pt' && data[0].Roles[0].RoleId==3){
            Swal.fire('Invalid credentials for Partner Login', '', 'error');
            return;
          }
          else if(this.code=='em' && data[0].Roles[0].RoleId!=3){
            Swal.fire('Invalid credentials for Employee Login', '', 'error');
            return;
          }else{
            window.location.assign('/dashboard?code='+this.code);
          }
        this.globalService.lendingpage=true;
      this.isLogedUser=true;  
      this.globalService.isLogin=true;  
      this.globalService.userName='Welcome,'+data[0].UserName;  
      sessionStorage.setItem('UserId',data[0].UserId.toString()); 
      sessionStorage.setItem('userName',data[0].UserName);
      sessionStorage.setItem('email',data[0].Email);
      sessionStorage.setItem('mobile',data[0].Mobile);
      
        
        //this.router.navigateByUrl('/dashboard');
      }else{
        Swal.fire('Userid and password not matched', '', 'error');
      }
    }else{
      Swal.fire('Userid and password not matched', '', 'error');
    }
    },((err:ErrorHandle) => {
      this.ErrorHandle=err;    
      Swal.fire(this.ErrorHandle.statusText, '', 'error');  
  }));

  }
  password() {
    this.show = !this.show;
}
  rememberMe(isChecked: boolean){
    if(isChecked) {
      let emilid=this.registerForm.value.Email;
      if(emilid !=null){
        localStorage.setItem('email',emilid);
        localStorage.setItem('UserId',emilid); 
        localStorage.setItem('access_token',this.token.access_token); 
      }      
    }else{
      localStorage.removeItem('email');
      localStorage.removeItem('UserId'); 
      localStorage.removeItem('access_token'); 
    }
  }

  forgotPassword(){
    let validate=true;
   
    if(this.registerForm.value.Email=="" || this.registerForm.value.Email==null){
      document.getElementById("Email").style.border = "1px solid red";
      validate=false;  
    }
    if (validate==true) {
    this.authService.forgotPassword(this.registerForm.value.Email)
    .subscribe((data)=>{          
      if(data>0){           
          Swal.fire('Password send your email id.', '', 'success');              
      }
      else{
         
          Swal.fire('Please contact administrator.', '', 'error');
      }         
      
    },((err:ErrorHandle) => {
      this.ErrorHandle=err;       
       Swal.fire(this.ErrorHandle.statusText, '', 'error');           
    })); 
  }
    }  
}
