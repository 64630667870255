import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { HealthservicesService } from '../services/healthservices.service';
import { HealthProposalService } from '../services/proposal.service';

import jsPDF from 'jspdf';
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// import htmlToPdfmake from 'html-to-pdfmake';

//import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css']
})
export class CompareComponent implements OnInit {
  CompareList1 = [];
  CompareList2 = [];
  CompareList3 = [];
  getCompareList = [];
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  constructor(private router: Router,
    private globalService: GlobalService,
    private healthMasterService: HealthservicesService,
    private healthService: HealthProposalService) { }

  ngOnInit() {
    this.getCompareList = JSON.parse(sessionStorage.getItem('compareArr'));
    debugger;
    var vid = "";
    for (let i = 0; i < this.getCompareList.length; i++) {
      let planTypeId = this.getCompareList[i].PlanTypeId;
      if (i == 0) {
        vid = planTypeId;
      } else {
        vid = vid + ',' + planTypeId;
      }
    }
    var quoteId = parseInt(sessionStorage.getItem('quotaionId'));
    this.healthMasterService.GetFeaturesByPlanType(vid, quoteId).subscribe((res: any[]) => {
     debugger
      if (res != null) {
        this.CompareList2 = res;
        this.CompareList1=this.CompareList2;
        this.getCompareList = JSON.parse(sessionStorage.getItem('compareArr'));


        for (let k = 0; k < this.getCompareList.length; k++) {

          for (let i = 0; i < this.getCompareList[k].MatchedFeatureList.length; i++) {
            for (let j = 0; j < this.CompareList2.length; j++) {
              if (this.getCompareList[k].MatchedFeatureList[i].FeatureID == this.CompareList2[j].Id) {
                const sIndex = this.CompareList1.indexOf(this.CompareList2[j]);
                var s = this.CompareList2[j]
                this.CompareList1.splice(sIndex, 1);
                this.CompareList1.unshift(s);
              }
            }
          }

          for (let i = 0; i < this.getCompareList[k].NotMatchedFeatureList.length; i++) {
            for (let j = 0; j < this.CompareList2.length; j++) {
              if (this.getCompareList[k].NotMatchedFeatureList[i].FeatureID == this.CompareList2[j].Id) {
                const sIndex = this.CompareList1.indexOf(this.CompareList2[j]);
                var s = this.CompareList2[j]
                this.CompareList1.splice(sIndex, 1);
                this.CompareList1.unshift(s);
              }
            }
          }
        }

         this.CompareList1=this.CompareList1;
      }
    });

  }
  ngAfterViewChecked() {
    
    this.getCompareList = JSON.parse(sessionStorage.getItem('compareArr'));

    for (let j = 0; j < this.getCompareList.length; j++) {
      for (let i = 0; i < this.getCompareList[j].MatchedFeatureList.length; i++) {
        $('.' + this.getCompareList[j].MatchedFeatureList[i].FeatureID).addClass("back_color_");
      }
      for (let i = 0; i < this.getCompareList[j].NotMatchedFeatureList.length; i++) {
        $('.' + this.getCompareList[j].NotMatchedFeatureList[i].FeatureID).addClass("back_color_");
      }
    }
  }

  savePremium(premium) {
    let quoteId = parseInt(sessionStorage.getItem('quotaionId'));
    premium.QuotionId = quoteId;
    this.healthService.postPremiumData(premium).subscribe((res: number) => {
      if (res)
        this.router.navigate(['/proposal'], { queryParams: { id: res } })

    })
  }

  filterCompare(type) {
    if (JSON.parse(sessionStorage.getItem('compareArr')) != null) {
      console.log(JSON.parse(sessionStorage.getItem('compareArr')));
      return JSON.parse(sessionStorage.getItem('compareArr'));
    }
  }

  filterItemsOfType(type) {
    if (this.CompareList1 != null) {
      return this.CompareList1.filter(x => x.Id != type);
    }

  }
  calPremiumToMonth(Premium) {
    var amount = this.numberToText(Math.round(Premium / 12));
    return amount;
  }
  // numberToText(SumInsured) {
  //   var Amount = SumInsured.toString();
  //   var lastThree = Amount.substring(Amount.length - 3);
  //   var otherNumbers = Amount.substring(0, Amount.length - 3);
  //   if (otherNumbers != '')
  //     lastThree = ',' + lastThree;

  //   return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  // }

  numberToText(SumInsured) { 
    debugger;
    var Amount = SumInsured.toString().split('.');
    var lastThree = Amount[0].substring(Amount[0].length - 3);
    var otherNumbers = Amount[0].substring(0, Amount[0].length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    if(Amount.length > 1) {
      return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree +'.'+ Amount[1]; 
    }
    else {
      return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree; 
    }
  }

  downloadAsPDF() {
    // const doc = new jsPDF();

    // const pdfTable = this.pdfTable.nativeElement;

    // var html = htmlToPdfmake(pdfTable.innerHTML);

    // const documentDefinition = { content: html };
    // pdfMake.createPdf(documentDefinition).open(); 
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      //const pdf=new jsPDF();
      let pdf = new jsPDF()
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
  }

}
