export class User {
    
    id: number;
    UserId:number;
    UserName: string;
    firstName: string;
    lastName: string;
    Mobile:string;
    Email:string;
    Password: string;
    ProfileId:string;
    confirmPassword: string;
    token: string;
    roleId:string;
    isChangePwd:number;
    statusName:string;
   Roles:Roles[];
}
export class Roles{
    RoleId:number;
    RoleName:string;
}
export class UserAuthentication{
    Password:string;
    UserName:string;
}
export class UserDetail {  
    CustomerId: number;
    Email: string;
    Mobile: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    GenderID: number;
    CustomerCode: string;
    DOB: string;
    RoleId: number;
    PasswordEnc: string;
    IsActive: boolean;
    IsBlacklist: boolean;
    CreatedBy: string;
}
export class LoginApiModel{
    EmailOrMobile :string;  
    userid:string;  
    password:string;
    client_id:string;  
    client_secret:string;
}
export class TokenRequest
{   
    userid :string;
    password :string;
    client_id :string;
    client_secret :string    ;    
}

export class ChangePasswod
{   
    userid :string;
    oldpassword :string;
    password :string;
    confirmPassword :string;    
}