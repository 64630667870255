
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HealthservicesService } from '../services/healthservices.service';
import { ProgressStatus, ProgressStatusEnum } from '../models/progress-status.model';

import { HttpEventType } from '@angular/common/http';
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import {AppSettings} from '../../globals';
declare var $: any;
@Component({
  selector: 'app-viewplan',
  templateUrl: './viewplan.component.html',
  styleUrls: ['./viewplan.component.css']
})
export class ViewplanComponent implements OnInit {
  QuotionId:number;
  companyId:number;
 img: string;
 cover:string;
 premiumForMonth :Number;
 annualPremium:Number;
 productId:Number;
 planId:Number;
 planName:string;
 productName:string;
 healthKeyFeatures: any[];
 cashlessHospitalsList: any[];
 healthDocumentsList: any[];
 HighlightsList:any[];
 claimProcessList:any[];
 orgClaimProcessList:any[];
 claimProcessDocPath:any;
 config: any;
 totalRecords: number = 0;
 textSearch:string;
 collection = { count: this.totalRecords, data: [] };
 @Output() public downloadStatus: EventEmitter<ProgressStatus>;
 ErrorHandle:ErrorHandle;
 isHighlightsList:boolean=false;
 isClaimProcessList:boolean=false;
 ishealthDocuments:boolean=false;

 companyList = [];
 CompanyEmail:any;
 CompanyPhone:any;
 CashlessHospitalLink:any;


  constructor(private routeres: Router,private router: ActivatedRoute,private viewPlanService:HealthservicesService) {
    this.downloadStatus = new EventEmitter<ProgressStatus>();
    //Create dummy data
    for (var i = 0; i < this.collection.count; i++) {
      this.collection.data.push(
        {
          id: i + 1,
          value: "items number " + (i + 1)
        }
      );
    }

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.collection.count
    };
   }
   pageChanged(event) {
    this.config.currentPage = event;
  }
  searchText: any;
  ngOnInit() {
    this.searchText="";
    $(".show-more").click(function () {
      if ($(".textSHowHide").hasClass("noArrowMain")) {
        $(this).text("Show Less");
      } else {
        $(this).text("Show More");
      }

      $(".textSHowHide").toggleClass("noArrowMain");
    });
    this.getPremium();

    this.getCompanyInformationList();
  }
  
  getPremium(){
    
    this.planName=sessionStorage.getItem('planName');
    this.productName=sessionStorage.getItem('productname');
    this.companyId=parseInt(sessionStorage.getItem('companyId'));
    this.productId=parseInt(sessionStorage.getItem('productId'));
    this.planId=parseInt(sessionStorage.getItem('planId'));
    this.img=sessionStorage.getItem('viewplanImg');
    this.cover=sessionStorage.getItem('viewplanCover');
    this.premiumForMonth=parseInt(sessionStorage.getItem('viewplanPremiumForMonth')) ; 
    this.annualPremium=parseInt(sessionStorage.getItem('viewplanAnnualPremium')) ;
    let id=this.router.snapshot.queryParamMap.get('id');
    this.QuotionId=parseInt(sessionStorage.getItem('quotaionId')) ; 

    let productId=parseInt(sessionStorage.getItem('productId'));
    let planId=parseInt(sessionStorage.getItem('planId'));
   // alert(id);
    this.viewPlanService.getHealthKeyFeatures(productId,planId).subscribe((res:any[])=>{
      this.healthKeyFeatures=res;
      //console.log(res);
    });

    
    // this.viewPlanService.getCashHospitalsList(this.searchText).subscribe((res:any[])=>{
    //   this.cashlessHospitalsList=res;
    //   //console.log(res);
    // });

    this.viewPlanService.getHealthDocumentsList(productId,planId).subscribe((res:any[])=>{
      this.healthDocumentsList=res;
      //console.log(res);
    },
    ((err:ErrorHandle) => {
      this.ErrorHandle=err;   
      this.ishealthDocuments=true;    
      //  if(this.ErrorHandle.statusText=="Bad Request"){
      //    this.ishealthDocuments=true;
      //    //Swal.fire(this.ErrorHandle.statusText, '', 'error');
         
      //  }
  }));
    let vcompanyId=this.companyId;
    let vplanId=this.planId;
    this.viewPlanService.GetHealthKeyHighlights(this.companyId,productId,vplanId).subscribe((res:any[])=>{
      this.HighlightsList=res;
      //console.log(res);
    },
    ((err:ErrorHandle) => {
      this.ErrorHandle=err;     
      this.isHighlightsList=true;  
      //  if(this.ErrorHandle.statusText=="Bad Request"){
      //    this.isHighlightsList=true;
      //    //Swal.fire(this.ErrorHandle.statusText, '', 'error');
         
      //  }
  }));

    // this.viewPlanService.GetClaimProcess(productId,planId).subscribe((res:any[])=>{
    this.viewPlanService.GetClaimProcess(0,planId).subscribe((res:any[])=>{

      debugger
      this.orgClaimProcessList=res;
      this.claimProcessList=res.filter((v,i,a)=>a.findIndex(t=>(t.ClaimProcessID === v.ClaimProcessID))===i);
      
      this.claimProcessDocPath= AppSettings.DomainUrl+ '/Upload/0/0/Claim%20Process/'+this.claimProcessList[0].FileNames.toString();
      
     // alert(this.claimProcessDocPath)
      //console.log(res);
    },
    ((err:ErrorHandle) => {
      debugger
      this.ErrorHandle=err;    
      this.isClaimProcessList=true;   
      //  if(this.ErrorHandle.statusText=="Bad Request"){
      //   this.isClaimProcessList=true;
                 
      //  }
  }));
  //this.getCashHospitalsList();
  }
  
  getCashHospitalsList(){
    //this.searchText = ((document.getElementById("textSearch") as HTMLInputElement).value);
    let state=((document.getElementById("state") as HTMLInputElement).value);
    let city=((document.getElementById("city") as HTMLInputElement).value);
    let pincode=((document.getElementById("pincode") as HTMLInputElement).value);
    let vproductId=this.productId;
    this.viewPlanService.getCashHospitalsList(state,city,pincode,vproductId).subscribe((res:any[])=>{
      this.cashlessHospitalsList=res;
      //console.log(res);
    });
  }

  public DownloadViewPlanFile(id,name) {
    this.downloadStatus.emit( {status: ProgressStatusEnum.START});
    this.viewPlanService.DownloadViewPlanFile(id).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            this.downloadStatus.emit( {status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100)});
            break;
          case HttpEventType.Response:
            this.downloadStatus.emit( {status: ProgressStatusEnum.COMPLETE});
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = name;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
        this.downloadStatus.emit( {status: ProgressStatusEnum.ERROR});
      }
    );
  }
  bindClaimProcess(id){
    //console.log(id);
    return this.orgClaimProcessList.filter(x => x.ClaimProcessID == id);
  }
  keyPress($event) {
    const pattern = /[0-9]/;
    const inputChar = $event.key;//String.fromCharCode($event).charCodeAt;
    if (!pattern.test(inputChar.toString())) { 
        event.preventDefault();
    }
}
buyNow(){
  sessionStorage.setItem('quotaionId',this.QuotionId.toString()); 
  let id=this.router.snapshot.queryParamMap.get('id');
  this.routeres.navigate(['/proposal'], { queryParams: { id: id } })
}


getCompanyInformationList() {
  debugger
  this.viewPlanService.getCompanyInformationList(Number(this.companyId)).subscribe((data: any[]) => {
    this.companyList = data;
    this.CompanyEmail=this.companyList[0].Email;
    this.CompanyPhone=this.companyList[0].Phone;
    this.CashlessHospitalLink=this.companyList[0].CashlessHospitalLink;   
  });
}

}
