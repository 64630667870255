import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit  {
  UserId:number;
  QuotesList=[];
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  constructor(private healthMasterService:HealthservicesService,private router:Router) { 
    if(sessionStorage.getItem('UserId')!=null){     
      this.UserId=parseInt(sessionStorage.getItem('UserId'));
    }else{
      this.router.navigate(['/health']);
    }
  }

  ngOnInit() {   
    
    this.GetHealthFeaturesList();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
  }

  GetHealthFeaturesList(){
    this.healthMasterService.GetFavouriteList(this.UserId).subscribe((data: any[]) => {      
      this.QuotesList = data;   
      this.dtTrigger.next();
    });
  }

  buttonInRowClick(id,QuotionId){
    localStorage.setItem('quotaionId',QuotionId.toString()); 
    this.router.navigate(['/proposal'], { queryParams: { id: id } })
  }


}