import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {AppSettings} from '../../globals';
import {proposer} from '../Models/premium.models';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    url=AppSettings.API_ENDPOINT;
    constructor(private http: HttpClient) { }

    getAuthentication(formJson: any){  
      const httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }       
      
      return this.http.post(this.url+'/Authenticate/LogIn',formJson,httpOptions);
    }
    userRegisterForm(formJson: any){  
      const httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }       
      
      return this.http.post(this.url+'/Authenticate/RegisterUser',formJson,httpOptions);
    }
    GetUserDetails(userid){  
      // const reqHeader = new HttpHeaders
      // ({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('access_token')});
      // console.log(reqHeader);
      let headers_object = new HttpHeaders().append('Authorization', 'Bearer '+sessionStorage.getItem('access_token'))
      .append('Strict-Transport-Security','max-age=31536000; includeSubDomains');
      return this.http.get(`${this.url}/Authenticate/GetUser/` + userid ,{ headers:headers_object});
    }
    geUser(userId:string){
      let headers_object = new HttpHeaders().append('Authorization', 'Bearer '+sessionStorage.getItem('access_token'));
      return this.http.get(this.url+'/Authenticate/GetUser?user='+userId,{
        headers: headers_object
      });
    }

    updateUserProfile(formJson: any){  
      const httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }       
      
      return this.http.post(this.url+'/Admin/UpdateUserProfile',formJson,httpOptions);
    }

    GetUserProfile(userId:string){
      let headers_object = new HttpHeaders().append('Authorization', 'Bearer '+sessionStorage.getItem('access_token'));
      return this.http.get(this.url+'/Admin/GetUserProfile?userid='+userId,{
        headers: headers_object
      });
    }

    forgotPassword(userId:string){
      return this.http.get(this.url+'/Authenticate/ForgetPassword?user='+userId);
    }

    changePassword(val: any) {
      return this.http.post(this.url + '/Authenticate/ChangePassword', val);
    }
}