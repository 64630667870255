import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import { Subject } from 'rxjs';
import { AppSettings } from 'src/app/globals';
import { GlobalService } from 'src/app/global.service';
import { AuthenticationService } from '../../health/services/authentication.service';
import { User } from '../../common/Models/userDetail';
import { EncrDecrService } from '../../health/services/EncrDecrService';

declare let $: any;

@Component({
  selector: 'app-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.css']
})
export class QuotationListComponent implements OnInit {

  UserId: number;
  tab_1: any;
  tab_2: any;
  QuotesList = [];
  QuotesListMotor = [];
  dtTrigger: Subject<any> = new Subject();
  dtTriggerMotor: Subject<any> = new Subject();
  dtOptionhlth: DataTables.Settings = {};
  dtOptions: DataTables.Settings = {};
  constructor(private healthMasterService: HealthservicesService, private router: Router, private globalService: GlobalService,
    private authService: AuthenticationService, private EncrDecr: EncrDecrService) {
    if (sessionStorage.getItem('UserId') != null) {
      this.globalService.lendingpage = true;
      this.UserId = parseInt(sessionStorage.getItem('UserId'));
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    debugger;

    this.dtOptionhlth = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25],
      processing: true,
      order:[7, 'desc']
    };
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25],
      processing: true,
      order:[7, 'desc']
    };
    // this.GetHealthFeaturesList();
    this.tab_1 = true;
    this.activate_tab1();
  }

  GetHealthFeaturesList() {
    debugger;
    $('#gridHealth').DataTable().destroy();
    this.healthMasterService.GetQuotesList(this.UserId).subscribe((data: any[]) => {
      this.QuotesList = data;
      this.dtTrigger.next();
    });
  }

  GetMotorFeaturesList() {
    debugger;
    $('#gridMotor').DataTable().destroy();
    this.healthMasterService.GetQuotesListMotor(this.UserId).subscribe((data: any[]) => {
      this.QuotesListMotor = data;
      this.dtTriggerMotor.next();
    });
  }

  buttonInRowClick(id, QuotionId) {
    localStorage.setItem('quotaionId', QuotionId.toString());
    this.router.navigate(['/proposal'], { queryParams: { id: id } })

  }

  MotorbuttonInRowClick(item) {
    debugger;
    localStorage.setItem('quotaionId', item.PolicyDetailsId.toString());
    let username = sessionStorage.getItem('email');
    // this.router.navigate(['/proposal'], { queryParams: { id: id } })
    this.GetUserDetails(item, username);
  }

  GetUserDetails(item, username) {
    this.authService.geUser(username)
      .subscribe((data: User[]) => {
        if (data.length > 0) {
          let Checkit = data[0].Email + '|' + data[0].Password;
          var encrypted = this.EncrDecr.set('123456$#@$^@1ERF', JSON.stringify(Checkit));
          if (item.VehicleType == 3) {
            // let paymentUrl=AppSettings.DomainUrlMotor+'/two-wheeler-quotation?sessionId='+id+'&&userid='+encrypted;
            let proposalUrl = AppSettings.DomainUrlMotor + 'proposal?sessionId=' + item.PolicyDetailsId + '&&planId=' + item.PlanTypeId + '&&userid=' + encrypted;
            window.location.href = proposalUrl;
          } else {
            // let paymentUrl=AppSettings.DomainUrlMotorCar+'/motor-quotation?sessionId='+id+'&&userid='+encrypted;
            let proposalUrl = AppSettings.DomainUrlMotorCar + 'proposal?sessionId=' + item.PolicyDetailsId + '&&planId=' + item.PlanTypeId + '&&userid=' + encrypted;
            window.location.href = proposalUrl;
          }

        }
      });
  }

  activate_tab1() {
    debugger;
    var HealthDetails = document.getElementById("Motor");
    HealthDetails.classList.remove("ui-tabs-active");
    var MotorDetails = document.getElementById("Health");
    MotorDetails.classList.add("ui-tabs-active");
    this.tab_1 = true;
    this.tab_2 = false;
    this.GetHealthFeaturesList();
  }

  activate_tab2() {
    debugger;
    var HealthDetails = document.getElementById("Motor");
    HealthDetails.classList.add("ui-tabs-active");
    var MotorDetails = document.getElementById("Health");
    MotorDetails.classList.remove("ui-tabs-active");
    this.tab_1 = false;
    this.tab_2 = true;
    this.GetMotorFeaturesList();
  }

}

