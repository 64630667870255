import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import { Subject } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  UserId:number;
  policies=[];
  noOfPolicy:number=0;
  totalPremium:number=0;
  totalEarning:number=0;
  totalReward:number=0;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  constructor(private healthMasterService:HealthservicesService,private router:Router,private globalService:GlobalService) {
    if(sessionStorage.getItem('UserId')!=null){     
      this.globalService.lendingpage=true;
      this.UserId=parseInt(sessionStorage.getItem('UserId'));
    }else{
      this.router.navigate(['/login']);
    }
   }

  ngOnInit() {
    this. getPolicies();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
    $("#dashboard_left_panel").click(function(){
      $(".left_panel").toggleClass("show_left_panel");
    });

    debugger
    let keysToRemove = ["quotaionId", "members","healthcovers", "compareArr","CoverType", "quotedata","customer"];

    for (var key of keysToRemove) {
        sessionStorage.removeItem(key);
    }
  }


  getPolicies(){
    
    this.healthMasterService.GetPolicies(this.UserId,'','','').subscribe((res:any)=>{
      this.policies=res;
      this.dtTrigger.next();
      this.policies.forEach((v,i)=>{
        if(v.Type=='Policy'){
        this.noOfPolicy=this.noOfPolicy+1;
        this.totalPremium+=v.NetPremium;
        this.totalReward+=v.RewardPoint;
        }
      })
    })
  }

  buttonInRowClick(id,QuotionId){
    localStorage.setItem('quotaionId',QuotionId.toString()); 
    this.router.navigate(['/proposal'], { queryParams: { id: id } })
  }


}
