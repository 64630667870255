import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ErrorHandle } from '../models/ErrorHandle';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/health/services/authentication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  DateOfBirth:string;
  profileForm:FormGroup;
  userName:string;
  userEmail:string;
  userMobile:string;
  loading = false;
  submitted = false;
  ErrorHandle:ErrorHandle;
  datePickerConfig: Partial<BsDatepickerConfig>;
  constructor(private router:Router,private formBuilder:FormBuilder,
   private healthMasterService:HealthservicesService,
   private authService:AuthenticationService) { 
    this.datePickerConfig = Object.assign({}, { containerClass: 'theme-blue' }) 
    if(sessionStorage.getItem('userName')!=null){     
      this.userName=sessionStorage.getItem('userName');
      this.userEmail=sessionStorage.getItem('email');
      this.userMobile=sessionStorage.getItem('mobile');
    }else{
      this.router.navigate(['/health']);
    }

    
  }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({   
      UserId :  new FormControl(0),
      UserName: new FormControl(null, Validators.required),      
      Email: new FormControl(null, [Validators.required, Validators.email]),
      Mobile: new FormControl(null, [Validators.required, Validators.pattern("[0-9]{10}")]),
      Gender: new FormControl(null, Validators.required),
      DateOfBirth: new FormControl(null, Validators.required),
      Pincode: new FormControl(null, Validators.required),
      State:new FormControl(null, Validators.required),
      City:new FormControl(null, Validators.required),
      Address1: new FormControl(null, Validators.required),
      Address2: new FormControl(null),
      Landmark: new FormControl(null)
      
  });
  this.GetUserProfile(this.userEmail);
  }
  get r() { return this.profileForm.controls; }
  updateProfile(){
    this.submitted = true;
        
        if (this.profileForm.invalid) {
            return;
        }
        this.loading = true;
        //console.log(this.profileForm.value);
        this.authService.updateUserProfile(this.profileForm.value).subscribe((data)=>{          
          if(data>0){
              this.loading = false;
              Swal.fire('User profile update successfully.', '', 'success');              
          }
          else{
              this.loading = false;
              Swal.fire('Some error.', '', 'error');
          }         
          
        },((err:ErrorHandle) => {
          this.ErrorHandle=err;       
           Swal.fire(this.ErrorHandle.statusText, '', 'error');           
      })); 
  }
  getPincode($event){
    let pin=$event.target.value;
    this.getCityByPincode(pin);
  }
  getCityByPincode(pin){
    this.healthMasterService.getPincodes(pin).subscribe((res:any[])=>{
      this.profileForm.get('State').setValue(res[0].StateName);
        this.profileForm.get('City').setValue(res[0].CityName);
    })
  }
  keyPress($event){
    const pattern = /[0-9]/;
    const inputChar = $event.key;//String.fromCharCode($event).charCodeAt;
    if (!pattern.test(inputChar.toString())) {    
        // invalid character, prevent input
        event.preventDefault();
    }
  }
  GetUserProfile(username){
    this.authService.GetUserProfile(username)
    .subscribe((data: any[]) =>{ 
      if(data[0].UserName !=""){        
        this.profileForm.get('UserId').setValue(data[0].UserId);
        this.profileForm.get('UserName').setValue(data[0].UserName);
        this.profileForm.get('Email').setValue(data[0].Email);
        this.profileForm.get('Mobile').setValue(data[0].Mobile);
        this.profileForm.get('Gender').setValue(data[0].Gender);
        this.profileForm.get('DateOfBirth').setValue(data[0].DateOfBirth);
        this.profileForm.get('Pincode').setValue(data[0].Pincode);
        this.profileForm.get('State').setValue(data[0].State);
        this.profileForm.get('City').setValue(data[0].City);
        this.profileForm.get('Address1').setValue(data[0].Address1);
        this.profileForm.get('Address2').setValue(data[0].Address2);
        this.profileForm.get('Landmark').setValue(data[0].Landmark);
      }else{
        Swal.fire('User profile not upload', '', 'error');
      }
    },((err:ErrorHandle) => {
      this.ErrorHandle=err;    
      Swal.fire(this.ErrorHandle.statusText, '', 'error');  
  }));

  }
}
