import { PrivacyPolicyComponent } from './common/privacy-policy/privacy-policy.component';
import { FavouriteComponent } from './common/favourite/favourite.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './common/account/login/login.component';
import { ProfileComponent } from './common/profile/profile.component';
import { BuildplanComponent } from './health/buildplan/buildplan.component';
import { CompareComponent } from './health/compare/compare.component';
import { HealthComponent } from './health/health/health.component';
import { PaymentfailedComponent } from './health/paymentfailed/paymentfailed.component';
import { PaymentsuccessComponent } from './health/paymentsuccess/paymentsuccess.component';
import { ProposalComponent } from './health/proposal/proposal.component';
import { QuotationsComponent } from './health/quotations/quotations.component';
import { Template1Component } from './health/template1/template1.component';
import { ViewplanComponent } from './health/viewplan/viewplan.component';
import { HomeComponent } from './home/home.component';
import { ApilogComponent } from './apilog/apilog.component';
import { QuotesComponent } from './common/quotes/quotes.component';
import { PaymentsummeryComponent } from './health/paymentsummery/paymentsummery.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { MypolicyComponent } from './common/mypolicy/mypolicy.component';
import { QuotationListComponent } from './common/quotation-list/quotation-list.component';
import { LendingpageComponent } from './lendingpage/lendingpage.component';
import { AppComponent } from './app.component';
// import { HomeComponent } from './home/home.component';
import { RegisterPospComponent } from './health/register-posp/register-posp.component';
import { AddPosDetailsComponent } from './health/add-pos-details/add-pos-details.component';
import { AddDetailsComponent } from './health/add-details/add-details.component';
import { AcceptedPosComponent } from './health/accepted-pos/accepted-pos.component';
import { RejectedPosComponent } from './health/rejected-pos/rejected-pos.component';
import { TotalPosComponent } from './health/total-pos/total-pos.component';
import { DashboardPOSPComponent } from './health/dashboard-posp/dashboard-posp.component';
import {OnholdPospComponent} from './health/onhold-posp/onhold-posp.component'
import { MyCommissionComponent } from './common/MyCommission/my-commission/my-commission.component';
import { MyBusinessComponent } from './common/MyBusiness/my-business/my-business.component';
import { MyinspectionComponent } from './common/myinspection/myinspection.component';
import { RenewalComponent } from './common/renewal/renewal.component';
import { MyrenewalComponent } from './common/myrenewal/myrenewal.component';
import { ChangePasswordComponent } from './common/account/change-password/change-password.component';
import { PospPendingRequestComponent } from './health/posp-pending-request/posp-pending-request.component';
import { CustomerInfoComponent } from './common/customer-info/customer-info.component';
import { HealthCompareComponent } from './health/health-compare/health-compare.component';

const routes: Routes = [
  {
    path: '',
    // component: HealthComponent,
    // pathMatch: 'full'
    component: LendingpageComponent 
  },
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'apilog',
    component: ApilogComponent,
    pathMatch: 'full'
  },
  {
    path: 'health',
    component: HealthComponent,
    pathMatch: 'full'
  },
  {
    path: 'buildplan',

    component: BuildplanComponent
  },
  {
    path: 'quotations',
    component: QuotationsComponent
  },
  {
    path: 'quotations',
    component: QuotationsComponent
  },
  {
    path: 'proposal',
    component: ProposalComponent
  },
  {
    path: 'viewplan',
    component: ViewplanComponent
  },
  {
    path: 'compare',
    component: CompareComponent
  },
  {
    path: 'paymentsuccess',
    component: PaymentsuccessComponent
  },
  {
    path: 'paymentfailed',
    component: PaymentfailedComponent
  },
  {
    path: 'login',
    component: LoginComponent
  }, 
  {
    path: 'profile',
    component: ProfileComponent
  }, 
  {
    path: 'quotes',
    component: QuotesComponent
  }, 
  {
    path: 'favourite',
    component: FavouriteComponent
  }, 
  {
    path: 'privacyPolicy',
    component: PrivacyPolicyComponent
  }, 
  {
    path: 'paymentsummery',
    component: PaymentsummeryComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'admin-dashboard',
    component: DashboardPOSPComponent
  },
  
  {
    path: 'mypolicy',
    component: MypolicyComponent
  },
  {
    path: 'quotesList',
    component: QuotationListComponent
  },
  {
    path: 'MyCommission',
    component: MyCommissionComponent
  },
  {
    path: 'MyBusiness',
    component: MyBusinessComponent
  },
  { path: 'register-posp', component: RegisterPospComponent },
  { path: 'add-details', component: AddDetailsComponent },
  { path: 'pos-details', component: AddPosDetailsComponent },
  { path: 'accepted-posp', component: AcceptedPosComponent },
  { path: 'rejected-posp', component: RejectedPosComponent },
  { path: 'total-posp', component: TotalPosComponent },
  { path: 'onhold-posp', component: OnholdPospComponent },
  { path: 'pending-posp', component: PospPendingRequestComponent },
  // { path: 'pos-dashboard', component: DashboardComponent_POSP }


  {
    path: 'myinspection',
    component: MyinspectionComponent
  },
  {
    path: 'renewal',
    // component: RenewalComponent
    component: MyrenewalComponent
  },
  {
    path:'change-password',
    component:ChangePasswordComponent
  },
{
  path:'Customer-Info',
  component:CustomerInfoComponent
},
// {
//   path : 'Health-Compare',
//   component: HealthCompareComponent
// }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
