import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HealthservicesService } from '../health/services/healthservices.service';
import {AppSettings} from '../globals';
import { EncrDecrService } from '../health/services/EncrDecrService';
import { AuthenticationService } from '../health/services/authentication.service';
import { User } from '../common/Models/userDetail';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  UserId:number;
  constructor(private globalService:GlobalService, private healthMasterService:HealthservicesService,private router:Router,private EncrDecr: EncrDecrService,
    private authService:AuthenticationService) {
    if(sessionStorage.getItem('UserId')!=null){     
      this.UserId=parseInt(sessionStorage.getItem('UserId'));
    }else{
      this.router.navigate(['/login']);
    }
   }

  ngOnInit() {
    this.globalService.lendingpage=true;
  }

  RedirectToSBIG(){
    let vendorrefno='riskcare_fe1648bf48bc3e151dbbb598ed15ba62';   
    // let vuid=1076;
    let vuid=sessionStorage.getItem('email');
    let paymentUrl=AppSettings.DomainUrl+'/MicroInsurance/RedirectToSBIG?userid='+vuid+'&&vendorrefno='+vendorrefno;
     window.location.href=paymentUrl;
    // this.healthMasterService.RedirectToSBIG(this.UserId).subscribe((res:any)=>{      
    // });
  }
  RedirectToMotor(){   
    let username=sessionStorage.getItem('email');
    this.GetUserDetails(username,'motor');
  }
  RedirectToMotorCar(){   
    let username=sessionStorage.getItem('email');
    this.GetUserDetails(username,'motorcar');
  }
  RedirectToCommercial(){
    let username=sessionStorage.getItem('email');
    this.GetUserDetails(username,'gcv');
  }
  GetUserDetails(username,type){
    this.authService.geUser(username)
    .subscribe((data: User[]) =>{ 
      if(data.length>0){ 
        let Checkit=data[0].Email+'|'+data[0].Password+'|'+sessionStorage.getItem('isCustomer')+'|'+sessionStorage.getItem('CustomerName')+'|'+sessionStorage.getItem('MobileNumber');    
        var encrypted = this.EncrDecr.set('123456$#@$^@1ERF', JSON.stringify(Checkit));
        if(type=="motor"){
          let paymentUrl=AppSettings.DomainUrlMotor+'/two-wheeler?userid='+encrypted;
          window.location.href=paymentUrl; 
        }else if(type=="gcv"){
          let paymentUrl=AppSettings.DomainUrlGCV+'/Commercial?userid='+encrypted;
          window.location.href=paymentUrl; 
        }else{
          let paymentUrl=AppSettings.DomainUrlMotorCar+'/car-Insurence?userid='+encrypted;
        window.location.href=paymentUrl; 
        }
             
    }
    });
  }
}
