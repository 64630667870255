import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import Validation from 'src/app/health/services/validation';
import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-register-posp',
  templateUrl: './register-posp.component.html',
  styleUrls: ['./register-posp.component.css']
})
export class RegisterPospComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private service: HealthservicesService, private router: Router) { }

  form: FormGroup = new FormGroup({});
  form1: FormGroup = new FormGroup({});
  submitted = false;
  otpBox: string = "N";
  phoneBox: string = "Y";
  MobileNumber: string = "";
  validateOTP: string = "";
  OTP: string = "";
  EOTP: string = "";
  Email: string = "";
  display: any;
  OTPSuccess: boolean=true;
  ngOnInit(): void {
    
    this.form1 = this.formBuilder.group(
      {
        OTP: [
          '',
          [
            Validators.required
          ]
        ],
        EOTP: [
          '',
          [
            Validators.required
          ]
        ]
      }
    );
    this.form = this.formBuilder.group(
      {


        MobileNumber: [
          '',
          [
            Validators.required,
            // Validators.minLength(10),  
            // Validators.maxLength(11),
            // Validators.max(10),
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
          ]
        ],
        Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],


      }



    );
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  get f1(): { [key: string]: AbstractControl } {
    return this.form1.controls;
  }
  captureOTP() {
    debugger;
    this.submitted = true;
    $('#nextotp').prop('disabled', true);
    if (this.form.invalid) {
      return;
    }
    var val = {
      IsActive: true,
      UserId: this.MobileNumber,
      MobileNo: this.MobileNumber,
      OTPExpiry: "",
      EmailId: this.Email,
      Remarks: "",
      Status: "",
      CreatedBy: "",
      CreatedByIP: ""
    }
    this.service.CreateUserLogin(val).subscribe((data: any) => {
      debugger;
      if(data.Status=='SUCCESS'){
        this.otpBox = "Y";
        this.phoneBox = "N";
        localStorage.setItem("EmailId", this.Email);
        $('#nextotp').prop('enabled', false);
        $('#nextotp').prop('disabled', true);
        this.timer(1);
      }else{
        Swal.fire("", data.ErrorDescription, "error");
        $('#nextotp').prop('disabled', false);
        $('#nextotp').prop('enabled', true);
      }
      
    });


  }
  OTPValidation() {
    debugger;
    this.submitted = true;
    if (this.form1.invalid) {
      return;
    }
    var val = {
      Criteria: "UserId",
      Value: this.MobileNumber
    }
    if(this.OTPSuccess==false){
      Swal.fire("", "Time out!! Please resend OTP", "error");
      return false;
    }
    this.service.Select_UserLoginBy_Id(val).subscribe((data: any) => {
      debugger;
      if (this.OTP == data[0].OTP && this.EOTP == data[0].EOTP) {
        localStorage.setItem("UserId", data[0].UserId);
        localStorage.setItem("UserEmail", this.Email);
        this.OTPSuccess=true;
        this.SelectUserProfileById();
        // this.timer(1);
        // this.router.navigate(["/add-details"]);
      }
      else {
        // this.OTPSuccess=false;
        if(this.OTP == data[0].OTP){
          Swal.fire("", "Mobile OTP is not correct", "error");
        }
        if(this.EOTP == data[0].EOTP){
          Swal.fire("", "Email OTP is not correct", "error");
        }
        else{
          Swal.fire("", "Please provide correct OTP", "error");
        }
        
      }
    });
  }
  resendOTP() {
    $('#btnresend').prop('disabled', true);
    var val = {
      UserId: this.MobileNumber,
      OTPExpiry: ""
    }
    this.service.ReSendOTP(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        $('#btnresend').prop('disabled', true);
        Swal.fire("", "OTP sent to mobile number and registered email id", "success");
        this.timer(1);
      }
      else {
        Swal.fire("", "Something went wrong", "error");
        $('#btnresend').prop('enabled', true);
      }
    })
  }
  SelectUserProfileById() {
    var val = {
      Criteria: "UserId",
      Value: this.MobileNumber
    }
    this.service.SelectUserProfileById(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {

        localStorage.setItem("Stage", data[0].Stage);
        // this.router.navigate(["/pos-details"]);
        window.open('pos-details','_self');
      }
      else {
        window.open('add-details','_self');
        // this.router.navigate(["/add-details"]);
      }
    });
  }

  timer(minute) {debugger;
    // let minute = 1;
    let seconds: number = minute * 90;
    let textSec: any = "0";
    let statSec: number = 90;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 89;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 90)}:${textSec}`;

      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.OTPSuccess=false;
        $('#btnresend').prop('disabled', false);
        $('#btnresend').prop('enabled', true);
      }
    }, 1000);
  }

}
