import { Component, OnInit,Input } from '@angular/core';
import { HealthservicesService } from '../services/healthservices.service';

@Component({
  selector: 'app-sidelist',
  templateUrl: './sidelist.component.html',
  styleUrls: ['./sidelist.component.css']
})
export class SidelistComponent implements OnInit {
@Input() show:boolean;
showCover:boolean=true;
BaseCoverageList=[];
  KeyList=[];
  HighlightList=[];
  startPage : any;
  paginationLimit:any; 

  startPage2 : Number;
  paginationLimit2:Number; 

  startPage3 : Number;
  paginationLimit3:Number; 
  constructor(private healthMasterService:HealthservicesService) { 
    
  }

  ngOnInit() {
    this.startPage = 0;
    this.paginationLimit = 6;

    this.startPage2 = 0;
    this.paginationLimit2 = 2;

    this.startPage3 = 0;
    this.paginationLimit3 = 2;
    debugger
    if(this.show!=undefined)
    this.showCover=this.show;
    let searchtext="Health"; 
    this.healthMasterService.GetBaseCoverage(searchtext).subscribe((res: any[]) => { 
      if (res != null) {
        for (let i = 0; i < res.length; i++) {
          if(res[i].Status =='true'){
            if(res[i].CategoryName =="Must to have"){
             this.BaseCoverageList.push({ item_text:res[i].Remarks,TooltipRemark:res[i].TooltipRemark}); 
            }
            if(res[i].CategoryName =="Good to have"){
             this.KeyList.push({ item_text:res[i].Remarks }); 
            }
            if(res[i].CategoryName =="Customized"){
             this.HighlightList.push({ item_text:res[i].Remarks }); 
            }
                  
          }          
        } 
      }
    });
  }
  showMoreItems(text)
   {
    if(text=="MusttoHave"){
      this.paginationLimit = Number(this.paginationLimit) + 6; 
     }

     if(text=="GoodtoHave"){
      this.paginationLimit2 = Number(this.paginationLimit2) + 2; 
     }

     if(text=="Customized"){
      this.paginationLimit3 = Number(this.paginationLimit3) + 2; 
     }
             
   }
   showLessItems(text)
    {
      if(text=="MusttoHave"){
        this.paginationLimit = Number(this.paginationLimit) - 6;
      }

      if(text=="GoodtoHave"){
          this.paginationLimit2 = Number(this.paginationLimit2) - 2;
      }

      if(text=="Customized"){
      this.paginationLimit3 = Number(this.paginationLimit3) - 2;
      }
   }
   
}
