import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import { Subject } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
declare var $: any;

@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.css']
})
export class RenewalComponent implements OnInit {

  UserId:number;
  Email:any;
  fromDate:any='';
  toDate:any='';
  policyNumber:string='';
  policies=[];
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(private healthMasterService:HealthservicesService,private router:Router,private globalService: GlobalService) { 
    if(sessionStorage.getItem('UserId')!=null){     
      this.UserId=parseInt(sessionStorage.getItem('UserId'));
      this.Email=sessionStorage.getItem('email');
    }else{
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.globalService.lendingpage = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
    $("#dashboard_left_panel").click(function(){
      $(".left_panel").toggleClass("show_left_panel");
    });

    this.healthMasterService.GetRenewalData(this.Email,this.policyNumber,this.fromDate,this.toDate).subscribe((res:any)=>{
      this.policies=res;
      this.dtTrigger.next(); 
      });
    
  }

}
