import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {AppSettings} from '../../globals';
import {proposer} from '../Models/premium.models';

@Injectable({
  providedIn: 'root'
})
export class HealthProposalService {
    url=AppSettings.API_ENDPOINT;
    constructor(private http: HttpClient) { }

    postPremiumData(data){
        const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        }        
        return this.http.post(this.url+'/HealthMaster/SaveHealthPremiumData',data,httpOptions);
      }

      getPremium(policyDetailsId,quoteId){
        return this.http.get(this.url+'/HealthMaster/GetHealthProposalPremium?QuotionId='+quoteId+'&PolicyDetailsId='+policyDetailsId);
      }

      getCustomer(quoteId){
        return this.http.get(this.url+'/Common/GetProposerCustomer/'+quoteId);
      }

      getQuestionaire(lobId:number,companyId:number){
        return this.http.get(this.url+'/HealthMaster/GetHealthQuestion?LobId='+lobId+'&CompanyId='+companyId);
      }
      getMaritalStatus(productId:number){
        return this.http.get(this.url+'/HealthMaster/GetHealthMaritalStatus?ProductId='+productId);
      }

      postProposalData(data){
       // let headers_object = new HttpHeaders().append('Authorization', 'Bearer '+localStorage.getItem('access_token'));
        const httpOptions = {
          headers: new HttpHeaders().append('Authorization', 'Bearer '+sessionStorage.getItem('access_token')).append('Content-Type','application/json')
        }        
        return this.http.post(this.url+'/ProposalApi/SaveHDFCHealthProposalDetails',data,httpOptions);
      }
      postDigitQuestionData(ProposalId,data){debugger;
        // let headers_object = new HttpHeaders().append('Authorization', 'Bearer '+localStorage.getItem('access_token'));
         const httpOptions = {
           headers: new HttpHeaders().append('Authorization', 'Bearer '+sessionStorage.getItem('access_token')).append('Content-Type','application/json')
         }        
         return this.http.post(this.url+'/ProposalApi/SaveDigitHealthQuestionDetails?ProposalId='+ProposalId,data,httpOptions);
       }
      postfavouritePremiumData(data){
        const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        }        
        return this.http.post(this.url+'/HealthMaster/SaveFavouritePremiumData',data,httpOptions);
      }

      getRelianceAddon(lobId:number,companyId:number){
        return this.http.get(this.url+'/HealthMaster/GetRelianceAddon?LobId='+lobId+'&CompanyId='+companyId);
      }
      
      SaveRelianceAddon(data :any){
        return this.http.post(this.url + '/ProposalApi/SaveRelianceAddon',data) ;
       }

  GetPremiumSummaryData(ProposalId) {
    return this.http.get(this.url + '/ProposalApi/GetPremiumSummaryData?ProposalId=' + ProposalId);
  }
  GetHealthPaymentDetails(ProposalId) {
    return this.http.get(this.url + '/ProposalApi/GetHealthPaymentDetails?ProposalId=' + ProposalId);
  }
   GetquoteIdData(id) {
    return this.http.get(this.url + '/HealthMaster/GetquoteIdData?QuotionId=' + id).toPromise();
    //return await this.http.get('http://localhost:11274/api/HealthMaster/GetquoteIdData?QuotionId='+id).toPromise();
  }
   SaveBasicMemberDetails(data :any){
    debugger;
    return this.http.post(this.url + '/ProposalApi/SaveBasicMemberDetails',data) ;
   }
   
   GetBasicMemberDetails(data :any){
    debugger;
    return this.http.post(this.url + '/ProposalApi/GetBasicMemberDetails',data) ;
   }
   SaveNominnneeDetails(data :any){
    return this.http.post(this.url + '/ProposalApi/SaveNominnneeDetails',data) ;
   }

   GetRliArea(data :any){
    // const httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': 'application/json'})
    // }        
    return this.http.post(this.url + '/HealthMaster/GetRliArea',data) ;
  }
}