import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms'
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { HealthservicesService } from '../services/healthservices.service';
import { SumInsured, FamilyMembers, Childrens, PinCode } from '../Models/healthMaster.models'
import { insuredMember, proposer } from '../Models/premium.models'
import { BuildplanComponent } from '../buildplan/buildplan.component';
import { ErrorHandle } from '../../common/models/ErrorHandle';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/global.service';

import { DateFormat } from "../../common/date-format";
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
//import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
declare var $: any;

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css'],
  providers: [
    { provide: DateAdapter, useClass: DateFormat }
  ]
})
export class HealthComponent implements OnInit {
  date: any = [];

  isLogin: boolean;
  isCustomer: boolean;
  floaterDob: any;
  serializedDate: any;
  proposarDetailsForm: FormGroup;
  floterDetailsForm: FormGroup;
  submitted = false;
  ischeck = false;
  showCover = true;
  datePickerConfig: Partial<BsDatepickerConfig>;
  public check: boolean;
  public members: insuredMember[];
  public flotermember: insuredMember = new insuredMember();
  dropdownList = [];
  dropdownListSumInsured = [];
  dropdownListChild = [];
  selectedItems = [];
  selectedItemsMember = [];
  selectedItemsChild = [];
  dropdownSettingsChild = {};
  dropdownSettings: IDropdownSettings = {};
  productForm: FormGroup;
  memberName: any;
  policytype: string;
  isSpouse: boolean;
  isSelf: boolean;
  proposer: proposer = new proposer();
  pincodes: PinCode[] = [];
  pincode: PinCode;
  BaseCoverageList = [];
  KeyList = [];
  HighlightList = [];
  ErrorHandle: ErrorHandle;
  UserId: number;
  @ViewChild(BuildplanComponent, { static: false }) buildplan: BuildplanComponent;
  @Input() isShowCover: boolean;
  idleState = 'Not started.';
  timedOut = false;

  primarySampleComponent: BuildplanComponent;
  public member: any[];
  constructor(private router: Router, private fb: FormBuilder, private globalService: GlobalService
    , private idle: Idle, private keepalive: Keepalive,//private bnIdle: BnNgIdleService,
    private healthMasterService: HealthservicesService, private dateAdapter: DateAdapter<Date>) {
    if (sessionStorage.getItem('UserId') != null) {
      this.UserId = parseInt(sessionStorage.getItem('UserId'));
    } else {
      this.router.navigate(['/login']);
    }
    this.datePickerConfig = Object.assign({}, { containerClass: 'theme-blue' })
    this.members = [];
    this.ischeck = false;
    //this.isSelf=true;
    this.date[0] = '';// new FormControl(new Date());
    this.floaterDob = '';
    this.proposer = new proposer();
    this.proposer.CustomerName = "";
    this.proposer.Mobile = "";
    this.proposer.Email = "";
    this.proposer.Pincode = "";

    this.flotermember.sumInsured = null;
    this.flotermember.dateOfBirth = "";
    this.flotermember.age = 0;

    this.pincode = new PinCode();


    dateAdapter.setLocale("en-in"); // DD/MM/YYYY
    if (sessionStorage.getItem('isCustomer') == 'true') {
      this.isCustomer = true;
    } else {
      this.isCustomer = false;
    }
    if (this.isCustomer == true) {
      if (this.isLogin == true) {
        idle.setIdle(5);
        idle.setTimeout(5);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        idle.onIdleEnd.subscribe(() => {
          this.idleState = 'No longer idle.';
          this.reset();
        });
        idle.onTimeout.subscribe(() => {
          debugger;
          this.idleState = 'Timed out!';
          this.timedOut = true;
        });
        idle.onIdleStart.subscribe(() => {
          this.idleState = 'You\'ve gone idle!'
        });
        idle.onTimeoutWarning.subscribe((countdown) => {
          this.idleState = 'Your session will expire in ' + countdown + ' seconds!'
        });
        this.reset();
      }
    }
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  ngOnInit() {
    this.globalService.lendingpage = true;
    if (this.isShowCover != undefined) this.showCover = this.isShowCover;
    this.bindModels();


    $(document).ready(function () {
      $('.js-btn-tooltip').tooltip();
      $('.js-btn-tooltip--custom').tooltip({
        customClass: 'tooltip-custom'
      });
      $('.js-btn-tooltip--custom-alt').tooltip({
        customClass: 'tooltip-custom-alt'
      });

      $('.js-btn-popover').popover();
      $('.js-btn-popover--custom').popover({
        customClass: 'popover-custom'
      });
      $('.js-btn-popover--custom-alt').popover({
        customClass: 'popover-custom-alt'
      });
      // $("#datepicker").datepicker({
      //   changeMonth: true,
      //   changeYear: true
      // });
    });



    this.check = false;
    //this.isSelf=true;
    $("#induinfo").hover(function () {
      $(".tooltopsindu").toggle();
    });

    $("#familyinfo").click(function () {
      $(".tooltopsfamily").toggle();
    });
    $(".closeTooltip").click(function () {
      $(".tooltops").hide("");
    });
    $("#pincodeinfo").click(function () {
      $(".tooltoppin").toggle();
    });

    $(".closeTooltip").click(function () {
      $(".tooltoppin").hide("");
    });


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsChild = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.fillMasterData();

    if (sessionStorage.getItem('CustomerName') != null) {
      this.proposer.CustomerName = sessionStorage.getItem('CustomerName');
    }

  }

  fillMasterData() {
    this.healthMasterService.getFamilyMembeers().subscribe((res: FamilyMembers[]) => {
      let tmp = [];
      if (res != null) {
        for (let i = 0; i < res.length; i++) {
          tmp.push({ item_id: res[i].MemberId, item_text: res[i].MemberName });
        }
        this.dropdownList = tmp;

      }
    });

    this.healthMasterService.getChilds().subscribe((res: Childrens[]) => {
      let tmp = [];
      if (res != null) {
        for (let i = 0; i < res.length; i++) {
          tmp.push({ item_id: res[i].ChildId, item_text: res[i].Name });
        }
        this.dropdownListChild = tmp;

      }
    });

    this.healthMasterService.getSumInsured().subscribe((res: SumInsured[]) => {
      let tmp = [];
      if (res != null) {
        for (let i = 0; i < res.length; i++) {
          tmp.push({ item_id: Number(res[i].SumInsuredValue) / 100000, item_text: res[i].SumInsuredText });
        }
        console.log(tmp);
        this.dropdownListSumInsured = tmp;

      }
    });

    //  var sumInsures=[{item_id:"1",item_text:"1 Lakh"},{item_id:"3",item_text:"3 Lakh"},{item_id:"5",item_text:"5 Lakh"}]
    //  this.dropdownListSumInsured =sumInsures;   

  }

  bindModels(): void {

    if (sessionStorage.getItem('CoverType') != null) {
      this.policytype = sessionStorage.getItem('CoverType');
      this.CoverType(this.policytype);
      if (sessionStorage.getItem('members') != null) {
        this.members = JSON.parse(sessionStorage.getItem('members'));
        this.members = this.filterMembers();
        let bindChlidDrop = [];
        let bindOtherMemberDrop = [];
        for (let i = 0; i < this.members.length; i++) {
          if (this.members[i].memberName == "Self")
            this.isSelf = true;
          if (this.members[i].memberName == "Spouse")
            this.isSpouse = true;
          if (this.members[i].memberName.slice(0, 5) == 'Child') {
            bindChlidDrop.push({ item_id: this.members[i].id, item_text: this.members[i].memberName });
          }
          if (this.members[i].memberName.slice(0, 5) != 'Child' && this.members[i].memberName != "Self" && this.members[i].memberName != "Spouse") {
            bindOtherMemberDrop.push({ item_id: this.members[i].id, item_text: this.members[i].memberName });
          }

          this.date[this.members[i].id] = new FormControl(new Date(this.members[i].dateOfBirth));
        }
        this.selectedItemsChild = bindChlidDrop; //||JSON;
        this.selectedItemsMember = bindOtherMemberDrop;
        if (this.policytype == "Individual") {

        }
        else {
          this.flotermember = JSON.parse(sessionStorage.getItem('members'))[0];
          this.floaterDob = new FormControl(new Date(this.flotermember.dateOfBirth));
          //this.isSelf=true;
        }
      }
    }
    if (!(sessionStorage.getItem('customer') == null || sessionStorage.getItem('customer') == ''))
      this.proposer = JSON.parse(sessionStorage.getItem('customer'));
    this.getCityByPincode(this.proposer.Pincode);


  }
  quantities(): FormArray {
    return this.productForm.get("quantities") as FormArray
  }
  newQuantity(memberName: any): FormGroup {
    return this.fb.group({
      name: '',
      age: '',
      memberName: memberName,
    })
  }
  addQuantity(memberId: any, memberName: any) {
    this.quantities().push(this.newQuantity(memberName));
    //this.member=this.quantities

    this.member.push({ id: memberId, name: memberName });

  }
  removeQuantity(i: number) {
    this.quantities().removeAt(i);
  }

  onItemSelectChild(item: any) {
    if (item.item_id != 0) {
      this.addMember(item.item_id, item.item_text);
    }

  }
  onDeSelectChild(item: any) {
    for (var i = 0; i < this.members.length; i++) {
      if (this.members[i].id == item.item_id) {
        if (this.members[i].memberName == item.item_text) {
          this.removeMember(i);
        }

      }
    }
  }


  onItemSelect(item: any) {
    $('#divFamilyMember').show();
    if (item.item_id != 0) {
      this.addMember(item.item_id, item.item_text);
    }
  }
  onDeSelect(item: any) {
    for (var i = 0; i < this.members.length; i++) {
      if (this.members[i].id == item.item_id) {
        if (this.members[i].memberName == item.item_text) {
          this.removeMember(i);
        }
      }
    }
  }


  //get f() { return this.proposarDetailsForm.controls; }
  RedirectToBuildPlan(): void {
    debugger;
    let validate = true;
    let floterValidate = true;
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var regexMobile = /^[6-9]\d{9}$/gm;
    if (this.proposer.CustomerName == "") {
      document.getElementById("customername").style.border = "1px solid red";
      validate = false;
    }
    else if (this.proposer.CustomerName != "") {
      document.getElementById("customername").style.border = "1px solid #A5A5A5";
      validate = true;
    }

    if (this.proposer.Mobile == "") {
      document.getElementById("mobile").style.border = "1px solid red";
      validate = false;
    }
    else if (this.proposer.Mobile != "") {
      document.getElementById("mobile").style.border = "1px solid #A5A5A5";
      validate = true;
    }

    if (this.proposer.Mobile == "") {
      document.getElementById("mobile").style.border = "1px solid red";
      validate = false;
    }
    else if (this.proposer.Mobile != "") {
      if (!regexMobile.test(this.proposer.Mobile)) {
        document.getElementById("mobile").style.border = "1px solid red";
        return;
      } else {
        document.getElementById("mobile").style.border = "1px solid #A5A5A5";
        validate = true;
      }
    }

    if (this.proposer.Email == "") {
      document.getElementById("email").style.border = "1px solid red";
      validate = false;
    }
    else if (this.proposer.Email != "") {
      if (!regex.test(this.proposer.Email)) {
        document.getElementById("email").style.border = "1px solid red";
        return;
      } else {
        document.getElementById("email").style.border = "1px solid #A5A5A5";
        validate = true;
      }
    }

    // if(this.proposer.Pincode ==""){
    //   document.getElementById("pincode").style.border = "1px solid red";
    //   validate=false;
    // }
    // else if(this.proposer.Pincode !=""){
    //   document.getElementById("pincode").style.border = "1px solid #A5A5A5";
    //   validate=true;
    // }
    if (this.pincode.PinCode == null || this.pincode.PinCode == "") {
      document.getElementById("pincode").style.border = "1px solid red";
      validate = false;
    }
    else if (this.pincode.PinCode.length < 6) {
      debugger;
      document.getElementById("pincode").style.border = "1px solid #A5A5A5";
      validate = false;
    }
    else if (this.pincode.PinCode != "" && this.pincode.PinCode.length == 6) {
      document.getElementById("pincode").style.border = "1px solid #A5A5A5";
      validate = true;
    }

    if (this.policytype == null) {
      validate = false;
      Swal.fire('Please policy type', '', 'error');
    }

    if (this.members.length == 0) {
      validate = false;
      Swal.fire('Please select member', '', 'error');
    }


    if (validate == true) {
      if (this.policytype == 'FamilyFloater') {
        if (this.flotermember.sumInsured == null) {
          document.getElementById("floaterSumDropdown").style.border = "1px solid red";
          floterValidate = false;
        }
        else if (this.flotermember.sumInsured != null) {
          document.getElementById("floaterSumDropdown").style.border = "1px solid #A5A5A5";
          floterValidate = true;
        }

        if (this.flotermember.dateOfBirth == null || this.flotermember.dateOfBirth == "") {
          document.getElementById("flotermember_Dob").style.border = "1px solid red";
          floterValidate = false;
        }
        else if (this.flotermember.dateOfBirth != "") {
          document.getElementById("flotermember_Dob").style.border = "1px solid #A5A5A5";
          floterValidate = true;
        }
        if (this.flotermember.age < 18) {
          Swal.fire('Elder member age minimum 18 years', '', 'error');
          return;
        }
        if (this.flotermember.age > 100) {
          Swal.fire('Age should not be more than 100 years', '', 'error');
          return;
        }
      }




      if (this.policytype == 'Individual') {
        for (let i = 0; i < this.members.length; i++) {
          let m = this.members[i];
          // let MemAge=this.returnAge(m.dateOfBirth);
          let memDOB = new Date(m.dateOfBirth);
          let timeDiff = Math.abs(Date.now() - memDOB.getTime());
          let MemAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

          if (m.age < 18 && m.memberName.slice(0, 5) != 'Child') {
            Swal.fire('Member age minimum 18 years', '', 'error');
            return;
          }
          if (MemAge > 100) {
            Swal.fire('Age should not be more than 100 years', '', 'error');
            return;
          }
          if (MemAge == 100) {

            var todayDate = new Date();
            var ageyear = todayDate.getFullYear() - memDOB.getFullYear();
            var agemonth = todayDate.getMonth() - memDOB.getMonth();
            var ageday = todayDate.getDate() - memDOB.getDate();
            let nowDay = new Date();
            if (ageyear > 100) {
              Swal.fire('Age should not be more than 100 years', '', 'error');
              return;
            }
            if (ageday > 0) {
              Swal.fire('Age should not be more than 100 years', '', 'error');
              return;
            }
            if (agemonth > 0) {
              Swal.fire('Age should not be more than 100 years', '', 'error');
              return;
            }
          }
          if (m.age < 1) {
            if (m.memberName.slice(0, 5) == 'Child') {
              let day = this.datetodays(m.dateOfBirth);
              if (day < 1) {
                Swal.fire('Child age minimum 1 day', '', 'error');
                return;
              }
            }
          }

          if (m.sumInsured == "") {
            document.getElementById("sumInsured_" + m.memberName).style.border = "1px solid red";
            floterValidate = false;
          }
          else if (m.sumInsured != "") {
            document.getElementById("sumInsured_" + m.memberName).style.border = "1px solid #A5A5A5";
            floterValidate = true;
          }

          if (m.dateOfBirth == null || m.dateOfBirth == "") {
            document.getElementById("dateOfBirth_" + m.memberName).style.border = "1px solid red";
            floterValidate = false;
          }
          else if (m.dateOfBirth != "") {
            document.getElementById("dateOfBirth_" + m.memberName).style.border = "1px solid #A5A5A5";
            floterValidate = true;
          }
        }
      } else {
        if (this.members.length < 2) {
          debugger
          floterValidate = false;
          Swal.fire('Select more than one member', '', 'error');
        }

      }

      if (floterValidate == true) {
        if (sessionStorage.getItem('CoverType') == 'Individual') {
          sessionStorage.setItem('members', JSON.stringify(this.members));
        }
        else {
          if (this.flotermember.memberName == null) this.flotermember.memberName = 'Elder';
          for (let i = 0; i < this.members.length; i++) {

            if (this.members[i].memberName == 'Elder')
              this.removeMember(i);
          }
          this.members.unshift(this.flotermember);
        }
        ///this.members=JSON.parse(localStorage.getItem('members'));
        let membersdata = [];

        for (let i = 0; i < this.members.length; i++) {
          let m = this.members[i];
          // if (localStorage.getItem('CoverType') != 'Individual'){
          if (m.memberName != 'Elder' && sessionStorage.getItem('CoverType') != 'Individual') {
            var currentdate = new Date();
            m.dateOfBirth = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
            m.age = 0;
          } else {
            var dob = new Date(m.dateOfBirth);
            m.dateOfBirth = dob.getFullYear() + '-' + (dob.getMonth() + 1) + '-' + dob.getDate();
          }
          //}

          let sumInsures = parseFloat(m.sumInsured) * 100000;

          // if(m.dateOfBirth==null){
          var currentbirthdate = new Date();
          var CurrentdateOfBirth = currentbirthdate.getFullYear() + '-' + (currentbirthdate.getMonth() + 1) + '-' + currentbirthdate.getDate();
          if (m.dateOfBirth == null || m.dateOfBirth == CurrentdateOfBirth) {
            membersdata.push({
              Relation: m.memberName, SumInsured: sumInsures, DateOfBirth: '1900-01-01', Age: m.age,
              weight: 0, height: 0, bmi: 0
            });
            // membersdata.push({Relation:m.memberName,SumInsured:sumInsures,DateOfBirth:this.members[0].dateOfBirth,Age:m.age,
            //   weight:0,height:0,bmi:0});
          }
          else {
            membersdata.push({
              Relation: m.memberName, SumInsured: sumInsures, DateOfBirth: m.dateOfBirth, Age: m.age,
              weight: 0, height: 0, bmi: 0
            });
          }


        }

        let quoteId = 0;
        if (sessionStorage.getItem('quotaionId') != null)
          quoteId = parseInt(sessionStorage.getItem('quotaionId'));
        let userId = sessionStorage.getItem('UserId')
        let policyType = sessionStorage.getItem('CoverType');
        let quoteData = {
          QuoteId: quoteId,
          HealthQuoteRequest: {
            SessionId: '',
            IPAddress: '',
            UserId: userId,
            ReqDate: new Date(),
            NoOfMembers: this.members.length,
            PolicyType: policyType,
            Portal: 'B2B'
          },
          HealthQuoteMembers: membersdata.filter((v, i, a) => a.findIndex(t => (t.Relation === v.Relation)) === i),
          BajajPolicyCoverData: {
            PanIndiaCover: '',
            RentCappingDiscount: '',
            WaiverRent: '',
            NonMedical: '',
            HPR: '',
            HPRValue: ''
          }
        }


        this.healthMasterService.postQuoteData(quoteData).subscribe((res: number) => {
          let data = res;
          sessionStorage.setItem('quotaionId', data.toString());
          this.proposer.Lob = 'HEALTH';
          this.proposer.QuotationId = data;
          this.healthMasterService.postCustomer(this.proposer).subscribe((res: any) => {
            let data = res;
            if (this.proposer != null)
              sessionStorage.setItem('customer', JSON.stringify(this.proposer));
            this.submitted = true;
            sessionStorage.setItem('members', JSON.stringify(this.members));
            if (this.showCover == true) {
              this.router.navigateByUrl('/buildplan');
            }
            //if(data===true) alert('saved');
          });
          if (this.showCover) {
            this.ischeck = true;
            // this.buildplan.getMembers();
          }
        });
      }
    }
  }

  RedirectToHealth(): void {
    this.ischeck = false;
    //this.router.navigateByUrl('/buildplan');
  }
  CoverType(type: string) {
    // alert(this.showCover);
    if (this.showCover == false) return false;

    if (sessionStorage.getItem('CoverType') == null) {
      sessionStorage.setItem('CoverType', type);
      this.policytype = type;
      if (sessionStorage.getItem('CoverType') == 'Individual') {
        $('#divFamilyFloater').hide();
        $('#divIndividual').show();

      } else {
        $('#divIndividual').hide();
        $('#divFamilyFloater').show();
        this.floaterDob = '';
      }
    }
    else {
      sessionStorage.setItem('CoverType', type);
      if (sessionStorage.getItem('CoverType') == 'Individual') {
        this.selectedItemsMember = [];
        this.selectedItemsChild = [];
        this.isSpouse = false;
        this.isSelf = true;
        //   this.members.forEach((element,index)=>{
        //     if(element.memberName !="Self"){
        //       //this.members.splice(index,1);
        //       this.removeMember(index);

        //     } 
        //  });
        this.members = [];
        this.addMember(1, 'Self');
        $('#divFamilyFloater').hide();
        $('#divIndividual').show();

      } else {
        this.selectedItemsMember = [];
        this.selectedItemsChild = [];
        this.isSpouse = false;
        this.members = [];
        this.addMember(1, 'Self');
        // this.members.forEach((element,index)=>{
        //   if(element.memberName !="Self"){
        //     //this.members.splice(index,1); 
        //     this.removeMember(index);           
        //   }
        // });
        $('#divIndividual').hide();
        $('#divFamilyFloater').show();

      }
    }
  }


  addMember(id: number, member: string) {
    for (let i = 0; i < this.members.length; i++) {
      if (this.members[i].memberName === member)
        return;
    }
    let insmember = new insuredMember();
    insmember.id = id;
    insmember.age = 0;
    this.date[id] = '';
    insmember.dateOfBirth = null;//yyyy+'-'+mm+'-'+dd;;
    // new FormControl(new Date());
    insmember.sumInsured = '5';
    insmember.memberName = member;
    this.members.push(insmember);
  }
  formControlValueChanged() {
    const phoneControl = this.proposarDetailsForm.get('phonenumber');
    if (sessionStorage.getItem('CoverType') != 'Individual') {
      phoneControl.setValidators([Validators.required]);
    }
    else if (sessionStorage.getItem('CoverType') === 'Individual') {
      phoneControl.clearValidators();
    }
    phoneControl.updateValueAndValidity();

  }

  removeMember(i: number) {
    this.members.splice(i, 1);
  }

  logValue() {
    console.log(this.members);
  }
  handleSelf($event) {
    if ($event.target.checked === true) {
      this.addMember(1, 'Self');

    } else {
      for (var i = 0; i < this.members.length; i++) {
        if (this.members[i].id == 1) {
          this.removeMember(i);

        }
      }
    }
  }
  handleSpouse($event) {
    if ($event.target.checked === true) {
      this.addMember(2, 'Spouse');

    } else {
      for (var i = 0; i < this.members.length; i++) {
        if (this.members[i].id == 2) {
          this.removeMember(i);
        }
      }
    }
  }

  calculateFloaterAge($event, member) {
    let age = this.returnAge($event);
    if (age < 18) {
      Swal.fire('Elder member age minimum 18 years', '', 'error');
    }

    if ($event == null) return;
    this.flotermember.dateOfBirth = $event.value;
    this.flotermember.age = age;
  }

  calculateAge($event, member, memberName) {
    debugger
    let age = 0;
    let day = 0;
    if ($event == null) return;
    if (memberName.slice(0, 5) == 'Child') {
      day = this.returnDays($event);
      if (day < 1) {
        day = 0;
        Swal.fire('Child age minimum 1 day', '', 'error');
      } else {
        age = this.returnAge($event);
      }
    } else {
      age = this.returnAge($event);
      if (age < 18) {
        Swal.fire('Member age minimum 18 years', '', 'error');
      }
    }

    if (age > 100) {
      Swal.fire('Age should not be more than 100 years', '', 'error');
    }

    if (age == 100) {
      // day=this.returnDays($event);
      // var totalYears = Math.abs(day / 365);
      // var totalMonths = Math.abs((day % 365) / 30);
      // var remainingDays = Math.abs((day % 365) % 30);
      // var trip = '';

      var todayDate = new Date();
      var ageyear = todayDate.getFullYear() - $event.value.getFullYear();
      var agemonth = todayDate.getMonth() - $event.value.getMonth();
      var ageday = todayDate.getDate() - $event.value.getDate();
      let nowDay = new Date();
      if (ageyear > 100) {
        Swal.fire('Age should not be more than 100 years', '', 'error');
      }
      if (ageday > 0) {
        Swal.fire('Age should not be more than 100 years', '', 'error');
      }
      if (agemonth > 0) {
        Swal.fire('Age should not be more than 100 years', '', 'error');
      }

      // if (agemonth <= 0) {
      //   ageyear--;
      //   agemonth = (12 + agemonth);
      // }
      // if (nowDay < $event.value) {
      //   agemonth--;
      //   ageday = 30 + ageday;
      // }  if (agemonth == 12) {
      //   ageyear = ageyear + 1;
      //   agemonth = 0;
      // }
    }

    let Insmembers = this.filterMembers()
    for (var i = 0; i < Insmembers.length; i++) {
      if (member == i) {
        Insmembers[i].age = age;
        Insmembers[i].dateOfBirth = $event.value;
      }
    }

    // this.products = this.tempproducts;  
    // this.products = this.products.filter(x => x.CreatedDate == $event.toJSON().split('T')[0]);  
  }

  onChangeSumInsured($event, mem) {

    mem.sumInsured = $event.target.value;
  }
  private datetodays(date) {
    let date1 = new Date(date);
    let date2 = new Date();
    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();
    // To calculate the no. of days between two dates
    var Diff_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Number(Diff_Days);
  }
  private returnDays($event) {
    if ($event != null) {
      let day = $event.value.getDate();//$event.getDate();
      let m = $event.value.getMonth()
      let y = $event.value.getFullYear();
      let date1 = new Date(y, m, day);
      //var date1 = new Date("06/30/2019");
      let date2 = new Date();

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();
      // To calculate the no. of days between two dates
      var Diff_Days = Difference_In_Time / (1000 * 3600 * 24);

      return Number(Diff_Days);
    }
  }
  private returnAge($event) {
    debugger;
    if ($event != null) {
      let day = $event.value.getDate();
      let m = $event.value.getMonth()
      let y = $event.value.getFullYear();
      let dt = new Date(y, m, day);

      var ageDifMs = Date.now() - dt.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age;
    }
  }

  getPincode($event) {
    let pin = $event.target.value;
    this.getCityByPincode(pin);
  }

  getCityByPincode(pin) {
    this.healthMasterService.getPincodes(pin).subscribe((res: PinCode[]) => {
      this.pincodes = res;
      if (res.length > 0)
        this.pincode = res[0];
    })
  }
  filterMembers() {
    if (this.members != null) {
      return this.members.filter(x => x.memberName != 'Elder');
    }

  }
  keyPress($event) {
    const pattern = /[0-9]/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar.toString())) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  onBlurMethod(val) {
    document.getElementById("mobile").style.border = "1px solid #A5A5A5";
    const regex = /^[6-9]\d{9}$/gm;
    const str = val;
    let m;
    if (m = regex.exec(str) == null) {

      document.getElementById("mobile").style.border = "1px solid red";

    } else {
      while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          //alert(`Found match, group ${groupIndex}: ${match}`);              

        });
      }
    }
  }

  alphaOnly($event) {
    var pattern = /^[a-zA-Z\s]*$/;///^[A-Za-z]+$/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  updateCalcs(event) {
    alert(event.value);
  }

}
