import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/health/services/authentication.service';
import { HealthservicesService } from 'src/app/health/services/healthservices.service';
import Validation from 'src/app/health/services/validation';

import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  UserId: number;
  //UserId: string = "";
  OLDPassword: string = "";
  NewPassword: string = "";
  ConfirmPassword: string = "";
  form: FormGroup = new FormGroup({});
  submitted = false;
  show: boolean = false;

  constructor(private service: HealthservicesService, private route: Router, private authService: AuthenticationService
    , private formBuilder: FormBuilder, private globalService: GlobalService) {

    if (sessionStorage.getItem('UserId') != null) {
      this.globalService.lendingpage = true;
      this.UserId = parseInt(sessionStorage.getItem('UserId'));
    } else {
      this.route.navigate(['/login']);
    }
  }

  ngOnInit() {
    //alert('hii')
    this.form = this.formBuilder.group(
      {
        OLDPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        NewPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        ConfirmPassword: ['', Validators.required],
      },

      {
        validators: [Validation.match('NewPassword', 'ConfirmPassword')]
      }
    );

  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  changePassword() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    var val = {
      UserID: sessionStorage.getItem("UserId"),
      OLDPassword: this.OLDPassword,
      NewPassword: this.NewPassword
    }
    debugger
    this.authService.changePassword(val).subscribe((Data: any) => {
      if (Data == true) {
        Swal.fire({
          title: 'Password change ',
          text: 'Password changed successfully !!',
          icon: 'success',
          //showCancelButton: true,
          confirmButtonText: 'Ok',
          // cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
            debugger;
            this.userLogout();
          } else if (result.isDismissed) {
          }
        })
      }
      else {
        Swal.fire('Current password is not correct', '', 'error');
      }
    });
  }

  userLogout() {
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('mobile');
    sessionStorage.removeItem('UserId');
    sessionStorage.removeItem('access_token');

    this.globalService.isLogin = false;
    //this.isLogin=false;
    // window.location.reload();
    // this.router.navigate(['/health']);
    this.route.navigate(['/'])
      .then(() => {
        window.location.reload();
      });
  }

}

