export class SumInsured {
    SumInsuredId: number;
    SumInsuredValue: number;
    SumInsuredText: string;
   
}

export class FamilyMembers {
    MemberId: number;   
    MemberName: string;
   
}
export class Childrens {
    ChildId: number;   
    Name: string;
   
}

export class healthCover{
    coverId:Number;
    parrentCoverId:number;
    name:string;
    description:string;

}

export class PinCode{
    StateCode:string;
    StateName: string;
    CityId: number;
    CityName:string;
    PinCode: string;
    Zone: string;
}